

export default [
  {
    header: "Modules"
  },
  {
    icon: "HomeIcon",
    title: "Home",
    children:
      [
        {
          icon: "CircleIcon",
          title: "Inicio",
          route:
          {
            name: "manufactura-home",
          }
        },


      ]
  },

  {
    icon: "DatabaseIcon",
    title: "Data",
    children:
      [

        {
          icon: "CircleIcon",
          title: "Carga de Archivo",
          route:
          {
            name: "manufactura-carga-data",
          }
        },

        {
          icon: "CircleIcon",
          title: "Skus",
          route:
          {
            name: "manufactura-skus",
          }
        },

        {
          icon: "CircleIcon",
          title: "Demanda",
          route:
          {
            name: "manufactura-demanda",
          }
        },

        {
          icon: "CircleIcon",
          title: "Lineas Productivas",
          route:
          {
            name: "manufactura-ajustes-lineas-productivas",
          }
        },

      ]
  },

  {
    icon: "TrendingUpIcon",
    title: "Predicciones",
    children:
      [
        {
          icon: "CircleIcon",
          title: "Modelos de Predicción",
          route:
          {
            name: "manufactura-modelos-prediccion",
          }
        },
        {
          icon: "CircleIcon",
          title: "Histórico Predicciones",
          route:
          {
            name: "manufactura-historico-prediccion",
          }
        }
      ]
  },


  {
    icon: "LayersIcon",
    title: "Planificaciones",
    children:
      [

        {
          icon: "CircleIcon",
          title: "Nueva Planificación 2",
          route:
          {
            name: "manufactura-nueva-planificacion2",
          }
        },
        {
          icon: "CircleIcon",
          title: "Histórico Planificación",
          route:
          {
            name: "manufactura-historico-planificaciones",
          }
        }
      ]
  },

  {
    icon: "SettingsIcon",
    title: "Ajustes y restricciones",
    children:
      [
        {
          icon: "CircleIcon",
          title: "Ajustes",
          route:
          {
            name: "manufactura-ajustes",
          }
        },
      ]
  },

  {
    icon: "HelpCircleIcon",
    title: "Ayuda",
    children:
      [
        {
          icon: "CircleIcon",
          title: "FAQ",
          route:
          {
            name: "manufactura-faq",
          }
        },

        {
          icon: "CircleIcon",
          title: "Terminos y condiciones",
          route:
          {
            name: "manufactura-terminos-y-condiciones",
          }
        },
      ]
  }
]
