export default {
    // Endpoints
    loginEndpoint: 'https://clients.bruna.ai/api/auth/login',
    refreshTokenEndpoint: 'https://clients.bruna.ai/api/auth/refresh',

    //logoutEndpoint: 'http://camanchaca.localhost:62907/api/auth/logout',
    meEndpoint: 'https://clients.bruna.ai/api/auth/me',
  
  
    // This will be prefixed in authorization header with token
    // e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',
  
    // Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageTokenExpirationDate: 'accessTokenExpirationDate', 
    

  
  }
  