<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <LvProgressBar v-if="isTopLoading" mode="indeterminate" color="#ff7f50" style="z-index: 99999; height: 0.2em;"/>    
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>   





<script>


import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import LvProgressBar from 'lightvue/progress-bar';

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

import useApiServices from '@/services/useApiServices.js';

import axios from '@axios'

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,

    LvProgressBar
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout()
    {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType()
    {
      return this.$store.state.appConfig.layout.type
    },

    isTopLoading()
    {
      return this.$store.state.appConfig.layout.isTopLoading
    },

  },

  watch: {

    //WATCH LOCALE CHANGES
    '$i18n.locale': function (newVal, oldVal)
    {

      $("#feedbacklabelspan").text(this.$t('Ayuda'))
    },

  },

  beforeUnmount()
  {
    if (this.checkConfigInterval)
    {
      console.log("checkConfigInterval clear!!");
      clearInterval(this.checkConfigInterval)
    }

    this.$pusher.unsubscribe('notifications');
  },

  created()
  {


  },

  methods: {

    waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) 
    {
        var startTimeInMs = Date.now();
        (function loopSearch() {
          if (document.querySelector(selector) != null) {
            callback();
            return;
          }
          else {
            setTimeout(function () {
              if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs)
                return;
              loopSearch();
            }, checkFrequencyInMs);
          }
        })();
    },



    checkConfig()
    {

      this.checkConfigInterval = setInterval(() =>
      {

        useApiServices.getConfig().then((response) =>
        {

          let server_config = response.data
          let local_config = localStorage.getItem("config");

          localStorage.setItem("config", JSON.stringify(server_config));

          if (local_config)
          {
            let local_config_decoded = JSON.parse(local_config)

            if (local_config_decoded.version !== server_config.version)
            {
              console.log("Version Mismatch", { local_config_decoded, server_config })
              window.location.reload(true);
            }

          }

        })

      }, 60 * 1000);

      console.log("checkVersionInterval setup!!");



    }



  },

  mounted()
  {

    this.checkConfig()

    let self = this;

    /**
     * Esta funcion espera a que este cargado el elemento del widget de Zoho para poder carga el username y useremail
     */

    this.waitForElementToDisplay("#feedbacklabel",function()
    {
      $("#feedbacklabel").on("click", function () {

        let userData = JSON.parse(localStorage.getItem("userData"));

        if(userData.email && userData.name)
        {
          $("#feedbNameTxtField").val(userData.name);
          $("#feedbEmailTxtField").val(userData.email);
        }        

      });

      


    },
    1000,
    10000);

    this.$gtag.config({
        'cookie_flags': 'max-age=7200;secure;samesite=none',
    })



    /*
    this.$zendesk.$on('loaded', (event) =>
    {
      console.log("Zendesk loaded!!!!")

      let userData = JSON.parse(localStorage.getItem("userData"));

      if(userData.email && userData.name && userData.client?.name)
      {
        this.$zendesk.identify({
          name:  userData.name,
          email: userData.email,
          organization: userData.client?.name
        })

      }

    })
*/

    //Se escucha a la llegada de las notificaciones de pusher

    var channel = this.$pusher.subscribe('notifications');
    channel.bind('NotificationEvent', function (data)
    {
      let userData = JSON.parse(localStorage.getItem("userData"));

      if (userData && userData.client.id == data.client_id)
      {
        /*
        let toastId = data.content.id

        data.content.component = ToastificationContent;

        self.$toast.dismiss(toastId);
        self.$toast(data.content, { id: toastId });
*/

      }


    });


  },

  beforeCreate()
  {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++)
    {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++)
    {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },


  setup()
  {


    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val =>
    {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })




    return {
      skinClasses,
      enableScrollToTop,

      checkConfigInterval: null
    }
  },
}
</script>
