
import ApiServices from "../apiServices";
import axios from '@axios'

export default class TipApiServices extends ApiServices {
  constructor() {
    super("/tip");

    this.operaciones = {

      tableInventarioConfig: this.baseUrl + "/operaciones/inventario/historico/config",
      tableInventarioData: this.baseUrl + "/operaciones/inventario/historico/data",

      tableCriaConfig: this.baseUrl + "/operaciones/cria/historico/config",
      tableCriaData: this.baseUrl + "/operaciones/cria/historico/data",
    }

    this.historicoFinanzaConfig = this.baseUrl + "/finanzas/config"
    this.historicoFinanzaData = this.baseUrl + "/finanzas/data"

    this.historicoPropuestaConfig = this.baseUrl + "/propuestas/config"
    this.historicoPropuestaData = this.baseUrl + "/propuestas/data"
    this.historicoPropuestaStatistics = this.baseUrl + "/propuestas/statistics"

    this.AnalisisRentabilidadChart1 = this.baseUrl + "/analisis/chart1"
    this.AnalisisRentabilidadChart2 = this.baseUrl + "/analisis/chart2"
    this.AnalisisRentabilidadStatistics = this.baseUrl + "/analisis/statistics"


  }
}


