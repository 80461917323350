export default [
	{
		header: "Modules"
	},
	{
		icon: "HomeIcon",
		title: "Home",
		children:
			[
				{
					icon: "CircleIcon",
					title: "Inicio",
					route:
					{
						name: "sap-home",
					}
				},

			]
	},
	
	{
		icon: "DatabaseIcon",
		title: "Data",
		children:
			[
				{
					icon: "CircleIcon",
					title: "Sap integración",
					route:
					{
						name: "sap-integracion",
					}
				},
				{
					icon: "CircleIcon",
					title: "Plantaciones",
					children: [
						{
							icon: "CircleIcon",
							title: "Historico Plantaciones",
							route:
							{
								name: "sap-data-plantaciones",
							}
						},
					]
				},
				{
					icon: "CircleIcon",
					title: "Cosechas",
					children: [
						{
							icon: "CircleIcon",
							title: "Historico Cosechas",
							route:
							{
								name: "sap-data-cosechas",
							}
						},
					]
				},
			]
	},
	{
    icon: "TrendingUpIcon",
    title: "Predicciones",
    children:
      [
        {
          icon: "CircleIcon",
          title: "Predicciones cosecha",
          route:
          {
            name: "sap-predicciones-cosechas",
          }
        }
      ]
  },
	// {
  //   icon: "TrendingUpIcon",
  //   title: "Optimizaciones",
  //   children:
  //     [
  //       {
  //         icon: "CircleIcon",
  //         title: "Optimización riego",
  //         route:
  //         {
  //           name: "",
  //         }
  //       },
	// 			{
  //         icon: "CircleIcon",
  //         title: "Optimización abono",
  //         route:
  //         {
  //           name: "",
  //         }
  //       }
  //     ]
  // },

	{
		icon: "SettingsIcon",
		title: "Ajustes y restricciones",
		children:
			[
				{
					icon: "CircleIcon",
					title: "SKU",
					route:
					{
						name: "sku",
					}
				}
			]
	},

	{
		icon: "HelpCircleIcon",
		title: "Ayuda",
		children:
			[
				{
					icon: "CircleIcon",
					title: "FAQ",
					route:
					{
						name: "sapdemo-faq",
					}
				},
				/* {
					icon: "CircleIcon",
					title: "Registro de actividad",
					route:
					{
						name: "pf-historico-notificaciones",
					}
				}, */
			]
	}
]
