import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
//import { Debug as DebugIntegration } from "@sentry/integrations";

import 'ag-grid-enterprise';

import App from './App.vue'
import VueGtag from "vue-gtag";
import Zendesk from '@dansmaculotte/vue-zendesk'
 
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'  

// Axios Mock Adapter
import '@/@fake-db/db'

import Highcharts from "highcharts";


//import HighchartsVue from "highcharts-vue";
import HighchartsVue from  '@/libs/highcharts-vue/src/index'



import Gantt from "highcharts/modules/gantt";
import Stock from "highcharts/modules/stock";
import BoxPlot from "highcharts/highcharts-more";
import Exporting from "highcharts/modules/exporting";
import HeatMap from "highcharts/modules/heatmap";
import Histogram from "highcharts/modules/histogram-bellcurve";

import DrillDown from "highcharts/modules/drilldown";
//import Winbarb from "highcharts/modules/windbarb";
import StockTools from "highcharts/modules/stock-tools";
import OfflineExporting from "highcharts/modules/offline-exporting";
import loadParallelCoordinates from "highcharts/modules/parallel-coordinates.js";

import IndicatorsAll from "highcharts/indicators/indicators-all";
import DragPanes from "highcharts/modules/drag-panes"
import AnnotationsAdvance from "highcharts/modules/annotations-advanced"

import PriceIndicator from "highcharts/modules/price-indicator"

import Accessibility from "highcharts/modules/accessibility"

import HighchartsTheme from "highcharts/themes/grid-light.js"; 
import HighchartsTheme2 from "highcharts/themes/avocado.js"; 

Stock(Highcharts);
Gantt(Highcharts);
Histogram(Highcharts);
 
Exporting(Highcharts); 
OfflineExporting(Highcharts);
loadParallelCoordinates(Highcharts);
BoxPlot(Highcharts);
DrillDown(Highcharts);
//Winbarb(Highcharts);

//IndicatorsAll(Highcharts);
DragPanes(Highcharts);
AnnotationsAdvance(Highcharts);
//PriceIndicator(Highcharts);

//StockTools(Highcharts);

Accessibility(Highcharts);



HighchartsTheme2(Highcharts);


HeatMap(Highcharts); 


Vue.use(HighchartsVue, {tagName: 'highcharts', highcharts: Highcharts});
//Vue.use(HighchartsVue2, {tagName: 'heatmap', highcharts: Highcharts2});


import VueNumber from 'vue-number-animation'

Vue.use(VueNumber)


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(require('vue-pusher'), {
    api_key: process.env.VUE_APP_PUSHER_API_KEY,
    options: {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        encrypted: true,
        debug: true,
        debugLevel: "all"
    }
});


 

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

require('@core/assets/fonts/oxanium/oxanium.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')


//console.log(process.env.NODE_ENV)


Vue.use(Zendesk, {
  key: process.env.VUE_APP_ZENDESK_KEY,
  disabled: false,
  hideOnLoad: true
})

Vue.use(VueGtag, {
  config: { 
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
    params: {
      send_page_view: true,
    }

  },
  appName: 'BrunaFront',
  pageTrackerScreenviewEnabled: true
}, router);


// Sentry.init({
//   Vue,
//   dsn: process.env.VUE_APP_SENTRY_DNS,
//   environment: process.env.VUE_APP_SENTRY_ENV ?? 'development',
//   initialScope: {
//     tags: { 
//       "developer": process.env.VUE_APP_SENTRY_DEVELOPER ?? 'default' 
//     },
//   },
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracePropagationTargets: ["localhost", "bruna.ai", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   autoSessionTracking: true,

//   beforeSend(event, hint) {

//     let showReportDialog = process.env.VUE_APP_SENTRY_SHOW_REPORT_DIALOG ?? false 

//     // Check if it is an exception, and if so, show the report dialog
//     if (event.exception && showReportDialog) 
//     {
//       Sentry.showReportDialog({ 
//         eventId: event.event_id
//       });
    
//     }
//     return event;
//   },

// });


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

