
import ApiServices from "../apiServices";

export default class SapDemoApiServices extends ApiServices {
  constructor() {
    super("/sapdemo");

		this.home = {

			resumen0: this.baseUrl + "/home/resumen0",
			resumen1: this.baseUrl + "/home/resumen1",
			resumen2: this.baseUrl + "/home/resumen2",
			resumen3: this.baseUrl + "/home/resumen3",
			resumen4: this.baseUrl + "/home/resumen4",

		}

		this.historicoPlantacionesSapDemoConfig = this.baseUrl + "/data/farms/config"
		this.historicoPlantacionesSapDemoData = this.baseUrl + "/data/farms/data"
		this.historicoPlantacionesSapDemoStatistics = this.baseUrl + "/data/farms/statistics"

		this.historicoClimaSapDemoConfig = this.baseUrl + "/data/farms/clima/config"
		this.historicoClimaSapDemoData = this.baseUrl + "/data/farms/clima/data"
		this.historicoClimaSapDemoStatistics = this.baseUrl + "/data/farms/clima/statistics"


		this.chartWindSapDemo = this.baseUrl + "/data/farms/clima/chart/wind"
		this.chartTempSapDemo = this.baseUrl + "/data/farms/clima/chart/temp"
		this.chartHumSapDemo = this.baseUrl + "/data/farms/clima/chart/hum"

		this.historicoCosechasConfig = this.baseUrl + "/data/crops/config"
		this.historicoCosechasData = this.baseUrl + "/data/crops/data"

		this.syncCrops = this.baseUrl + "/populate/crops"



  }

}

