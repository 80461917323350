

export default [
  {
    header: "Modules"
  },
  {
    icon: "HomeIcon",
    title: "Home",
    children:
      [
        {
          icon: "CircleIcon",
          title: "Inicio",
          route:
          {
            name: "nestle-home",
          }
        },

      ]
  },
  {
    icon: "DatabaseIcon",
    title: "Data",
    children:
      [

        {
          icon: "CircleIcon",
          title: "Histórico de data",
          route:
          {
            name: "nestle-historico-data",
          }
        }
      ]
  },
  {
    icon: "TrendingUpIcon",
    title: "Predicciones",
    children:
      [
        {
          icon: "CircleIcon",
          title: "Simulación de Escenarios",
          route:
          {
            name: "not-active",
          }
        },
        {
          icon: "CircleIcon",
          title: "Histórico Predicciones",
          route:
          {
            name: "nestle-historico-predicciones",
          }
        }
      ]
  },
]
