import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'


import pages from "./routes/vuexy/pages";


Vue.use(VueRouter)

import useMultiTenant from '@/auth/multitenant/useMultitenant'


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...pages,
    ...useMultiTenant.routes,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})


// https://router.vuejs.org/guide/advanced/navigation-guards.html#optional-third-argument-next

router.beforeEach((to, _, next) => 
{
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) 
  {
    // Redirect to login if not logged in
    if (!isLoggedIn)
    {
      return next({ name: 'auth-login' })
    }
    else
    {
      // If logged in => not authorized
      return next({ name: 'misc-not-authorized' })
    }

  }
  else
  {
    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) 
    {
      const userData = getUserData()
      //return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
      return next('/')
    }
    else
    {
      return next()
    }



  }

})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
