export default [
	{
		header: "Modules"
	},
	{
		icon: "HomeIcon",
		title: "Home",
		children:
			[
				{
					icon: "CircleIcon",
					title: "Inicio",
					route:
					{
						name: "pf-home",
					}
				},

			]
	},
	
	{
		icon: "DatabaseIcon",
		title: "Data",
		children:
			[
				{
					icon: "CircleIcon",
					title: "Carga de Archivo",
					route:
					{
						name: "pf-carga-data",
					}
				},
				{
					icon: "CircleIcon",
					title: "Demanda",
					children: [
						{
							icon: "CircleIcon",
							title: "Ingreso Demanda",
							route:
							{
								name: "pf-ingreso-demanda",
							}
						},
						{
							icon: "CircleIcon",
							title: "Historico Demanda",
							route:
							{
								name: "pf-historico-demanda",
							}
						},
					]
				},
				{
					icon: "CircleIcon",
					title: "Materia Prima",
					children: [
						{
							icon: "CircleIcon",
							title: "Ingreso Materia Prima",
							route:
							{
								name: "pf-ingreso-materia-prima",
							}
						},
						{
							icon: "CircleIcon",
							title: "Histórico Materia Prima",
							route:
							{
								name: "pf-historico-materia-prima",
							}
						},
						{
							icon: "CircleIcon",
							title: "Stock Materia Prima",
							route:
							{
								name: "pf-ingreso-stock-materia-prima",
							}
						},
					]
				},
				{
					icon: "CircleIcon",
					title: "Aditivos",
					children: [
						{
							icon: "CircleIcon",
							title: "Ingreso aditivos",
							route:
							{
								name: "pf-ingreso-aditivos",
							}
						},
					]
				},
			]
	},

	{
		icon: "LayersIcon",
		title: "Blending",
		children:
			[
				{
					icon: "CircleIcon",
					title: "Nueva Planificación",
					route:
					{
						name: "pf-nueva-planificacion",
					}
				},
				{
					icon: "CircleIcon",
					title: "Planificación Recetas",
					route:
					{
						name: "pf-historico-planificacion",
					}
				},
			]
	},

	{
		icon: "SettingsIcon",
		title: "Ajustes y restricciones",
		children:
			[
				{
					icon: "CircleIcon",
					title: "SKU",
					route:
					{
						name: "pf-ajustes-sku",
					}
				}
			]
	},

	{
		icon: "HelpCircleIcon",
		title: "Ayuda",
		children:
			[
				{
					icon: "CircleIcon",
					title: "FAQ",
					route:
					{
						name: "pf-faq",
					}
				},
				/* {
					icon: "CircleIcon",
					title: "Registro de actividad",
					route:
					{
						name: "pf-historico-notificaciones",
					}
				}, */
			]
	}
]
