

export default [
  {
    header: "Modules"
  },
  {
    icon: "HomeIcon",
    title: "Home",
    children:
      [
        {
          icon: "CircleIcon",
          title: "Inicio",
          route:
          {
            name: "tip-home",
          }
        },

      ]
  },
  {
    icon: "ListIcon",
    title: "Operaciones",
    children:
      [
        {
          icon: "CircleIcon",
          title: "Inventario",
          route:
          {
            name: "tip-operaciones-inventario",
          }
        },
        {
          icon: "CircleIcon",
          title: "Cría",
          route:
          {
            name: "tip-operaciones-cria",
          }
        }
      ]
  },
  {
    icon: "LayersIcon",
    title: "Finanzas",
    route:
    {
      name: "tip-finanzas",
    }
  },
  {
    icon: "TrendingUpIcon",
    title: "Análisis Datos",
    route:
    {
      name: "tip-analisis",
    }
  },
  {
    icon: "ArchiveIcon",
    title: "Propuestas",
    route:
    {
        name: "tip-propuestas",
    }
},

]
