
import apps from './apps'
import dashboard from './dashboard'
import uiElements from './ui-elements/index'
import pages from './pages'
import chartsMaps from './charts-maps'
import formsTable from './forms-tables'
import others from './others'



export default [

    //{ path: '/', redirect: { name: 'dashboard-ecommerce' } },

    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
   

];