export default [
	{
		path: "/",
		name: "fiordo-home",
		component: () => import("@/views/clients/fiordo/home2/Home.vue"),
		meta: {
			pageTitle: "Inicio",
			breadcrumb: [
				{
					text: "",
					active: true
				}
			]
		}
	},
	{
		path: "/dashboard/fiordo/ajustes-y-restricciones/lineas-productivas",
		name: "dashboard-fiordo-restricciones-lineas-productivas",
		component: () => import("@/views/clients/fiordo/restricciones/lineasProductivas/LineasProductivas.vue"),
		meta: {
			pageTitle: "Lineas Productivas",
			breadcrumb: [
				{
					// text:
					//     "Estas viendo la vista inicial de cargar de formulario. ",
					text: "",
					active: true,
				},
			],
		},
	},
	{
		path: "/dashboard/fiordo/ajustes-y-restricciones/sku-precios-calidad",
		name: "dashboard-fiordo-restricciones-sku",
		component: () => import("@/views/clients/fiordo/restricciones/RestriccionesSku.vue"),
		meta: {
			pageTitle: "SKU/Precios/Calidad",
			breadcrumb: [
				{
					// text:
					//     "Estas viendo la vista inicial de cargar de formulario. ",
					text: "",
					active: true,
				},
			],
		},
	},
	/*
	{
		path: "/dashboard/fiordo/ajustes-restricciones/operacionales",
		name: "dashboard-fiordo-restricciones-operacionales",
		component: () =>
			import(
				"@/views/clients/fiordo/operaciones/Operaciones.vue"
			),
		meta: {
			pageTitle: "Operaciones",
			breadcrumb: [
				{
					// text:
					//     "Estas viendo la vista inicial de nueva planificacion. ",
					text: "",
					active: true
				}
			]
		}
	},*/

	{
		path: "/dashboard/fiordo/data/ingreso-contratos",
		name: "dashboard-fiordo-data-ingreso-contratos",
		component: () =>
			import(
				"@/views/clients/fiordo/carga-data/IngresoContratos.vue"
			),
		meta: {
			pageTitle: "Demanda Contratos",
			breadcrumb: [
				{
					// text:
					//     "Estas viendo la vista inicial de nueva planificacion. ",
					text: "",
					active: true
				}
			]
		}
	},
	{
		path: "/dashboard/fiordo/carga-data",
		name: "dashboard-fiordo-carga-data",
		component: () =>
			import(
				"@/views/clients/fiordo/carga-data/CargaData.vue"
			),
		meta: {
			pageTitle: "Carga de Archivo",
			breadcrumb: [
				{
					// text:
					//     "Estas viendo la vista inicial de nueva planificacion. ",
					text: "",
					active: true
				}
			]
		}
	},
	{
		path: "/dashboard/fiordo/blending/detalle-planificacion/:planification_id",
		name: "dashboard-fiordo-blending-detalle-planificacion",
		component: () => import("@/views/clients/fiordo/blending/DetallePlanificacion.vue"),
		meta: {
			pageTitle: "Detalle Planificación Blending",
			breadcrumb: [
				{
					// text:
					//     "Estas viendo la vista inicial de cargar de formulario. ",
					text: "",
					active: true,
				},
			],
		},
	},
	{
		path: "/dashboard/fiordo/blending/detalle-planificacion-blending-semanal",
		name: "dashboard-fiordo-blending-detalle-planificacion-blending-semanal",
		component: () => import("@/views/clients/fiordo/blending/DetallePlanificacionBlendingSemanal.vue"),
		meta: {
			pageTitle: "Detalle Planificación Blending",
			breadcrumb: [
				{

					text: "",
					active: true,
				},
			],
		},
	},
	{
		path: "/dashboard/fiordo/blending/detalle-match-contratos",
		name: "dashboard-fiordo-blending-match-contratos",
		component: () =>
			import(
				"@/views/clients/fiordo/blending/DetalleMatchContratos.vue"
			),
		meta: {
			pageTitle: "Match Contratos - Ver Detalle",
			breadcrumb: [
				{
					// text:
					//     "Estas viendo la vista inicial de nueva planificacion. ",
					text: "",
					active: true
				}
			]
		}
	},
	{
		path: "/fiordo/historico-planificaciones",
		name: "dashboard-fiordo-historico-planificacion",
		component: () =>
			import(
				"@/views/clients/fiordo/historico-planificaciones/HistoricoPlanificaciones.vue"
			),
		meta: {
			pageTitle: "Histórico Planificaciones",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},

	{
		path: "/fiordo/historico-stock",
		name: "dashboard-fiordo-historico-stock",
		component: () =>
			import(
				"@/views/clients/fiordo/historico-stock/HistoricoStock.vue"
			),
		meta: {
			pageTitle: "Stock Lotes",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},

	{
		path: "/fiordo/stock/potencial-stock/:planification_id",
		name: "dashboard-fiordo-potencial-stock",
		component: () =>
			import(
				"@/views/clients/fiordo/stock/PotencialStock.vue"
			),
		meta: {
			pageTitle: "Dashboard Potencial Stock",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
	{
		path: "/fiordo/dashboard-global/:planification_id",
		name: "dashboard-fiordo-global",
		component: () =>
			import(
				"@/views/clients/fiordo/dashboard-global/DashboardGlobal.vue"
			),
		meta: {
			pageTitle: "Dashboard Global",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
	{
		path: "/dashboard/fiordo/blending/nueva-planificacion",
		name: "dashboard-fiordo-nueva-planificacion",
		component: () => import("@/views/clients/fiordo/blending/NuevaPlanificacion.vue"),
		meta: {
			pageTitle: "Nueva Planificación",
			breadcrumb: [
				{
					// text:
					//     "Estas viendo la vista inicial de nueva planificacion. ",
					text: "",
					active: true
				}
			]
		}
	},
	{
		path: "/fiordo/data/asociacion-lotes",
		name: "dashboard-fiordo-asociacion-lotes",
		component: () => import("@/views/clients/fiordo/data/asociacion-lotes/AsociacionLotes.vue"),
		meta: {
			pageTitle: "Asociar Lotes QAD",
			breadcrumb: [
				{
					text: "",
					active: true
				}
			]
		}
	},
	{
		path: "/dashboard/fiordo/blending/detalle-planificacion-blending",
		name: "dashboard-fiordo-blending-detalle-planificacion-blending",
		component: () => import("@/views/clients/fiordo/blending/DetallesPlanificacionBlending.vue"),
		meta: {
			pageTitle: "Detalle Planificación Blending",
			breadcrumb: [
				{

					text: "",
					active: true,

				},
			],
		},
	},
	{
		path: "/fiordo/blending/reasignacion-lotes",
		name: "dashboard-fiordo-reasignacion",
		component: () => import("@/views/clients/fiordo/blending/ReasignacionLotes.vue"),
		meta: {
			pageTitle: "Reasignacion Lotes",
			breadcrumb: [
				{
					text: "",
					active: true
				}
			]
		}
	},
	{
		path: "/fiordo/blending/planificacion/reasignacion-lotes/:lote_id",
		name: "dashboard-fiordo-blending-planificacion-reasignacion-lotes",
		component: () =>
			import(
				"@/views/clients/fiordo/blending/PlanificacionReasignacionLotes.vue"
			),
		meta: {
			pageTitle: "",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
	{
		path: "/dashboard/fiordo/blending/detalle-planificacion-blending2",
		name: "dashboard-fiordo-blending-detalle-planificacion-blending2",
		component: () => import("@/views/clients/fiordo/blending/DetallesPlanificacionBlending2.vue"),
		meta: {
			pageTitle: "Detalle Planificación Blending",
			breadcrumb: [
				{

					text: "",
					active: true,

				},
			],
		},
	},
	{
		path: "/dashboard/fiordo/blending/reasignar-lote-contrato/:contrato_id",
		name: "dashboard-fiordo-blending-reasignar-lote-contrato",
		component: () =>
			import(
				"@/views/clients/fiordo/blending/ReasignarLoteContrato.vue"
			),
		meta: {
			pageTitle: "Reasignar Lote Contrato",
			breadcrumb: [
				{
					// text:
					//     "Estas viendo la vista inicial de nueva planificacion. ",
					text: "",
					active: true
				}
			]
		}
	},
	{
		path: "/dashboard/fiordo/restricciones-logistica",
		name: "dashboard-fiordo-restricciones-logistica",
		component: () => import("@/views/clients/fiordo/ajustes/Lojistica.vue"),
		meta: {
			pageTitle: "Logística",
			breadcrumb: [
				{
					text: "",
					active: true
				}
			]
		}
	},
	{
		path: "/dashboard/fiordo/blending/historico-blending",
		name: "dashboard-fiordo-blending-historico-blending",
		component: () =>
			import(
				"@/views/clients/fiordo/blending/HistoricoBlending.vue"
			),
		meta: {
			pageTitle: "Histórico Blending Planificado v/s Real",
			breadcrumb: [
				{
					// text:
					//     "Estas viendo la vista inicial de nueva planificacion. ",
					text: "",
					active: true
				}
			]
		}
	},
	{
		path: "/dashboard/fiordo/historico-blending/detail/:blending_id",
		name: "dashboard-fiordo-historico-blending-detail",
		component: () =>
			import(
				"@/views/clients/fiordo/blending/DetalleBlending.vue"
			),
		meta: {
			pageTitle: "Detalle de Blending",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
	{
		path: "/dashboard/fiordo/historico-notificaciones",
		name: "dashboard-fiordo-hitorico-notificaciones",
		component: () =>
			import(
				"@/views/clients/fiordo/notificaciones/NotificationsList.vue"
			),
		meta: {
			pageTitle: "Registro de Actividad",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},

	{
		path: "/dashboard/fiordo/not-active",
		name: "dashboard-fiordo-not-active",
		component: () =>
			import(
				"@/views/clients/fiordo/error/NotActive.vue"
			),
		meta: {
			pageTitle: "Histórico de Predicciones",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
	{
		path: "/dashboard/fiordo/historico-demanda",
		name: "dashboard-fiordo-hitorico-demanda",
		component: () =>
			import(
				"@/views/clients/fiordo/carga-data/HistoricoDemanda.vue"
			),
		meta: {
			pageTitle: "Historico Demanda",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
	/*
	{
		path: "/dashboard/fiordo/consolidando-demanda",
		name: "dashboard-fiordo-consolidando-demanda",
		component: () =>
			import(
				"@/views/clients/fiordo/carga-data/ConsolidandoDemanda.vue"
			),
		meta: {
			pageTitle: "Consolidando Demanda",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},*/
];
