export default [
    { path: "/", redirect: { name: "camanchaca-home" } },

    {
        path: "/camanchaca/ajustes",
        name: "camanchaca-ajustes",
        component: () =>
            import("@/views/clients/camanchaca/ajustes/Ajustes.vue"),
        meta: {
            pageTitle: "Ajustes",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/camanchaca/ajustes/lineas-productivas",
        name: "camanchaca-ajustes-lineas-productivas",
        component: () =>
            import("@/views/clients/camanchaca/ajustes-restricciones/lineas-productivas/LineasProductivas.vue"),
        meta: {
            pageTitle: "Ajustes Lineas Productivas",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/camanchaca/ajustes/cosecha-produccion",
        name: "camanchaca-ajustes-cosecha-y-produccion",
        component: () =>
            import("@/views/clients/camanchaca/ajustes-restricciones/cosecha-y-produccion/CosechaYProduccion.vue"),
        meta: {
            pageTitle: "Ajustes Cosecha y Produccion",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/camanchaca/ajustes/comerciales",
        name: "camanchaca-ajustes-comerciales",
        component: () =>
            import("@/views/clients/camanchaca/ajustes-restricciones/comerciales/Comerciales.vue"),
        meta: {
            pageTitle: "Ajustes Comerciales",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },


    {
        path: "/camanchaca/stock-jaulas",
        name: "camanchaca-stock-jaulas",
        component: () =>
            import("@/views/clients/camanchaca/jaulas/StockJaulas.vue"),
        meta: {
            pageTitle: "Stock Jaulas",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/camanchaca/stock-transito",
        name: "camanchaca-stock-transito",
        component: () =>
            import("@/views/clients/camanchaca/jaulas/StockJaulas.vue"),
        meta: {
            pageTitle: "Stock Transito",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/camanchaca/stock-acopio",
        name: "camanchaca-stock-acopio",
        component: () =>
            import("@/views/clients/camanchaca/jaulas/StockJaulas.vue"),
        meta: {
            pageTitle: "Stock Acopio",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/camanchaca/historico-clientes",
        name: "camanchaca-historico-clientes",
        component: () => import("@/views/clients/camanchaca/buyers/BuyersList.vue"),
        meta: {
            pageTitle: "Histórico de Clientes",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ]
        },
    },

    {
        path: "/camanchaca/historico-demanda",
        name: "camanchaca-historico-demanda",
        component: () =>
            import("@/views/clients/camanchaca/demanda/HistoricoDemanda.vue"),
        meta: {
            pageTitle: "Histórico de Demanda",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ]
        },
    },


    {
        path: "/camanchaca/carga-data",
        name: "camanchaca-carga-data",
        component: () =>
            import("@/views/clients/camanchaca/carga-data/CargaData.vue"),
        meta: {
            pageTitle: "Carga de Archivo",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },


    {
        path: "/camanchaca/home",
        name: "camanchaca-home",
        component: () => import("@/views/clients/camanchaca/home2/Home.vue"),
        meta: {
            pageTitle: "Inicio",
            breadcrumb: [
                {
                    text: "Consola de Administración",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/camanchaca/compra-mp",
        name: "camanchaca-compra-materia-prima",
        component: () => import("@/views/clients/camanchaca/compra-mp/CompraMateriaPrima.vue"),
        meta: {
            pageTitle: "Compra MP",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/camanchaca/historico-planificaciones/detail/:planification_id",
        name: "camanchaca-planification-detail",
        component: () =>
            import(
                "@/views/clients/camanchaca/planificacion-detalle/DetallePlanificacion.vue"
            ),
        meta: {
            pageTitle: "Detalle de Planificación",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/camanchaca/historico",
        name: "camanchaca-historico-predicciones",
        component: () =>
            import("@/views/clients/camanchaca/predicciones2/Historico.vue"),
        meta: {
            pageTitle: "Histórico de Predicciones",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/camanchaca/modelos",
        name: "camanchaca-modelos-resultados",
        component: () =>
            import("@/views/clients/camanchaca/modelos/ResumenModelo.vue"),
        meta: {
            pageTitle: "Arquitectura de Modelos de Predicción",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },

    /*
    {
      path: "/camanchaca/planning",
      name: "camanchaca-planning",
      component: () => import("@/views/clients/camanchaca/planning/Planning.vue"),
      meta: {
        pageTitle: "Plan de cosecha 90 días",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
   */
    {
        path: "/camanchaca/nueva-planificacion",
        name: "camanchaca-nueva-planificacion",
        component: () =>
            import(
                "@/views/clients/camanchaca/nueva-planificacion/NuevaPlanificacion.vue"
            ),
        meta: {
            pageTitle: "Nueva Planificación",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/camanchaca/demanda-irrestricta",
        name: "camanchaca-demanda-irrestricta",
        component: () =>
            import(
                "@/views/clients/camanchaca/demanda-irrestricta-v2/DemandaIrrestricta.vue"
            ),
        meta: {
            pageTitle: "Demanda Irrestricta Consolidada",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/camanchaca/carga-formularios-ingreso-contratos",
        name: "camanchaca-ingreso-contratos",
        component: () =>
            import(
                "@/views/clients/camanchaca/carga-formularios/IngresoContrato.vue"
            ),
        meta: {
            pageTitle: "Ingreso de Contrato",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/camanchaca/freights",
        name: "camanchaca-freights",
        component: () =>
            import("@/views/clients/camanchaca/freights/FreightsList.vue"),
        meta: {
            pageTitle: "Fletes",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ]
        },
    },


    {
        path: "/camanchaca/skus",
        name: "camanchaca-skus",
        component: () => import("@/views/clients/camanchaca/skus/SkusList.vue"),
        meta: {
            pageTitle: "Skus",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/camanchaca/freights",
        name: "camanchaca-freights",
        component: () =>
            import("@/views/clients/camanchaca/freights/FreightsList.vue"),
        meta: {
            pageTitle: "Freights",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },


    {
        path: "/camanchaca/comparar-planificaciones",
        name: "camanchaca-comparar-planificaciones",
        component: () =>
            import(
                "@/views/clients/camanchaca/planificaciones/CompararPlanificaciones.vue"
            ),
        meta: {
            pageTitle: "Comparador Planificaciones",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },



    /*
    {
      path: "/camanchaca/simulacion-escenarios",
      name: "camanchaca-simulacion-escenarios",
      component: () =>
        import(
          "@/views/clients/camanchaca/planificaciones2/HistoricoPlanificaciones.vue"
        ),
      meta: {
        pageTitle: "Simulación de Escenarios",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/camanchaca/comparacion-escenarios-all",
      name: "camanchaca-comparacion-escenarios-all",
      component: () =>
        import(
          "@/views/clients/camanchaca/planificaciones2/ComparacionEscenariosAll.vue"
        ),
      meta: {
        pageTitle: "Comparación de Escenarios",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
  
    {
      path: "/camanchaca/simulacion-escenarios/compare-runs/",
      name: "compare-runs",
      component: () =>
        import(
          "@/views/clients/camanchaca/planificaciones2/CompareRuns.vue"
        ),
      meta: {
        pageTitle: "Compare Runs",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  */





    /*
    {
      path: "/camanchaca/plan-cosecha",
      name: "camanchaca-plan-cosecha",
      component: () =>
        import("@/views/clients/camanchaca/plan-cosecha/PlanCosecha.vue"),
      meta: {
        pageTitle: "Plan Cosecha",
        breadcrumb: [
          {
            // text:
            //     "Estas viendo la vista inicial de Historico planificaciones. ",
            text: "",
            active: true,
          },
      },
  
  
      {
          path: "/camanchaca/historico-planificaciones",
          name: "camanchaca-historico-planificaciones",
          component: () =>
              import(
                  "@/views/clients/camanchaca/planificaciones/HistoricoPlanificaciones.vue"
              ),
          meta: {
              pageTitle: "Histórico Planificaciones",
              breadcrumb: [
                  {
                      text: "",
                      active: true,
                  },
              ],
          },
      },
  
      /*
      {
        path: "/camanchaca/plan-cosecha",
        name: "camanchaca-plan-cosecha",
        component: () =>
          import("@/views/clients/camanchaca/plan-cosecha/PlanCosecha.vue"),
        meta: {
          pageTitle: "Plan Cosecha",
          breadcrumb: [
            {
              // text:
              //     "Estas viendo la vista inicial de Historico planificaciones. ",
              text: "",
              active: true,
            },
          ],
        },
      },
      */
    {
        path: "/camanchaca/cosecha-urgente",
        name: "camanchaca-cosecha-urgente",
        component: () => import("@/views/vuexy/error/Error404.vue"),
        meta: {
            pageTitle: "Cosecha Urgente",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/camanchaca/ajustes-restricciones-cosecha",
        name: "camanchaca-cosecha",
        component: () =>
            import("@/views/clients/camanchaca/ajustes-restricciones/Cosecha.vue"),
        meta: {
            pageTitle: "Cosecha",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/camanchaca/ajustes-restricciones-matriz-sku",
        name: "camanchaca-matriz-sku",
        component: () =>
            import("@/views/clients/camanchaca/ajustes-restricciones/MatrizSku.vue"),
        meta: {
            pageTitle: "Matriz SKU/Líneas Productivas",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/camanchaca/ajustes-restricciones-lineas-costos",
        name: "camanchaca-lineas-costos",
        component: () =>
            import(
                "@/views/clients/camanchaca/ajustes-restricciones/LineasCostos.vue"
            ),
        meta: {
            pageTitle: "Líneas y Costos Productivos",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/camanchaca/ajustes-restricciones-curva-crecimiento",
        name: "camanchaca-curva-crecimiento",
        component: () =>
            import(
                "@/views/clients/camanchaca/ajustes-restricciones/CurvaCrecimiento.vue"
            ),
        meta: {
            pageTitle: "Curva de Crecimiento",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/camanchaca/ajustes-restricciones-descansos-centros",
        name: "camanchaca-descansos-centros",
        component: () =>
            import(
                "@/views/clients/camanchaca/ajustes-restricciones/DescansoCentros.vue"
            ),
        meta: {
            pageTitle: "Descansos Centros",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },
    /*
    {
      path: "/camanchaca/materia-prima-comprada",
      name: "camanchaca-materia-prima-comprada",
      component: () =>
        import(
          "@/views/clients/camanchaca/materia-prima-comprada/MateriaPrimaComprada.vue"
        ),
      meta: {
        pageTitle: "Materia Prima Comprada",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },*/

    {
        path: "/camanchaca/muestreo-jaulas/dashboard-global",
        name: "camanchaca-muestreo-jaulas-dashboard-global",
        component: () =>
            import(
                "@/views/clients/camanchaca/muestreo-jaulas/DashboardGlobalMuestreo.vue"
            ),
        meta: {
            pageTitle: "Dashboard Global Muestreo",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/camanchaca/muestreo-jaulas/identificacion-niveles-defectos",
        name: "camanchaca-muestreo-jaulas-identificacion-niveles-defectos",
        component: () =>
            import(
                "@/views/clients/camanchaca/muestreo-jaulas/IdentificacionNivelesDefectos.vue"
            ),
        meta: {
            pageTitle: "Identificación Niveles de Defectos",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/camanchaca/muestreo-jaulas/formulario-muestreo",
        name: "camanchaca-muestreo-jaulas-formulario-muestreo",
        component: () =>
            import(
                "@/views/clients/camanchaca/muestreo-jaulas/FormularioMuestreo.vue"
            ),
        meta: {
            pageTitle: "Formulario Muestreo",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/camanchaca/historico-notificaciones",
        name: "camanchaca-hitorico-notificaciones",
        component: () =>
            import(
                "@/views/clients/camanchaca/notificaciones/NotificationsList.vue"
            ),
        meta: {
            pageTitle: "Registro de Actividad",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },

    {
        path: "/camanchaca/faq",
        name: "camanchaca-faq",
        component: () =>
            import(
                "@/views/clients/camanchaca/faq/Faq.vue"
            ),
        meta: {
            pageTitle: "Preguntas Frecuentes",
            breadcrumb: [
                {
                    text: "",
                    active: true,
                },
            ],
        },
    },



];
