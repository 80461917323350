import axios from '@axios'
import ApiServices from "../apiServices";


export default class SqmApiServices extends ApiServices {
  constructor() {
    super("/sqm");

    this.documentsUploadDocument = this.baseUrl + "/documents/uploadDocument"
    this.documentsDownloadTemplate = this.baseUrl + "/documents/downloadTemplate"

    this.historicoCargaConfig = this.baseUrl + "/historicoCarga/config"
    this.historicoCargaData = this.baseUrl + "/historicoCarga/data"
    this.historicoCargaStatistics = this.baseUrl + "/historicoCarga/statistics"
    
    this.deleteReportes = this.baseUrl + "/reportes/eliminar"
    this.incidentesCreate = this.baseUrl + "/reportes/incidentes/create"
    this.historicoIncidentesConfig = this.baseUrl + "/reportes/incidentes/config"
    this.historicoIncidentesData = this.baseUrl + "/reportes/incidentes/data"
    this.reporteIndidentesEdit = this.baseUrl + "/reportes/incidentes/edit"

    this.hallazgosCreate = this.baseUrl + "/reportes/hallazgos/create"
    this.historicoHallazgosConfig = this.baseUrl + "/reportes/hallazgos/config"
    this.historicoHallazgosData = this.baseUrl + "/reportes/hallazgos/data"
    this.reporteHallazgosEdit = this.baseUrl + "/reportes/hallazgos/edit"

    this.confirmacionRolCreate = this.baseUrl + "/reportes/confirmacion/rol/create"
    this.historicoConfirmacionRolConfig = this.baseUrl + "/reportes/confirmacion/rol/config"
    this.historicoConfirmacionRolData = this.baseUrl + "/reportes/confirmacion/rol/data"

    this.irVerCreate = this.baseUrl + "/reportes/irver/create"
    this.historicoIrVerConfig = this.baseUrl + "/reportes/irver/config"
    this.historicoIrVerData = this.baseUrl + "/reportes/irver/data"

    this.liderazgoCreate = this.baseUrl + "/reportes/liderazgo/create"
    this.historicoLiderazgoConfig = this.baseUrl + "/reportes/liderazgo/config"
    this.historicoLiderazgoData = this.baseUrl + "/reportes/liderazgo/data"
    this.reporteLiderazgoEdit = this.baseUrl + "/reportes/liderazgo/edit"


    this.confirmacionProcesoCreate = this.baseUrl + "/reportes/confirmacion/proceso/create"
    this.historicoConfirmacionProcesosConfig = this.baseUrl + "/reportes/confirmacion/proceso/config"
    this.historicoConfirmacionProcesosData = this.baseUrl + "/reportes/confirmacion/proceso/data"

    this.historicoPrediccionesStatistics = this.baseUrl + "/historicoPredicciones/statistics"
    this.historicoPrediccionesChart = this.baseUrl + "/historicoPredicciones/chart1"
    this.historicoPrediccionesConfig = this.baseUrl + "/historicoPredicciones/config"
    this.historicoPrediccionesData = this.baseUrl + "/historicoPredicciones/data"
    
    this.historicoPrediccionesIncidentesStatistics = this.baseUrl + "/prediccionIncidentes/statistics"   
    this.historicoPrediccionesIncidentesChart1 = this.baseUrl + "/prediccionIncidentes/chart1"   
    this.historicoPrediccionesIncidentesChart2 = this.baseUrl + "/prediccionIncidentes/chart2"   

    this.chartAccidentabilidadDemo = this.baseUrl + "/demo/prediccionIncidentes/chart"
    this.statisticsAccidentabilidadDemo = this.baseUrl + "/demo/prediccionIncidentes/statistics"

    this.ultimaPrediccionConfig = this.baseUrl + "/demo/prediccionIncidentes/table/diario/config"
    this.ultimaPrediccionData = this.baseUrl + "/demo/prediccionIncidentes/table/diario/data"

    this.historicoPrediccionDemoConfig = this.baseUrl + "/demo/prediccionIncidentes/table/historico/config"
    this.historicoPrediccionDemoData = this.baseUrl + "/demo/prediccionIncidentes/table/historico/data"

    this.historicoGlobalConfig = this.baseUrl + "/reportes/gobal/config"
    this.historicoGlobalData = this.baseUrl + "/reportes/gobal/data"

    this.historicoPrediccionConfig = this.baseUrl + "/prediccionIncidentes/config"
    this.historicoPrediccionData = this.baseUrl + "/prediccionIncidentes/data"

  }

  postIncidentesCreate(data, vue) {
    return axios.post(this.incidentesCreate, data)
  }
  postHallazgosCreate(data, vue) {
    return axios.post(this.hallazgosCreate, data)
  }
  postConfirmacionRolCreate(data, vue) {
    return axios.post(this.confirmacionRolCreate, data)
  }

  postIrVerCreate(data, vue) {
    return axios.post(this.irVerCreate, data)
  }
  postLiderazgoCreate(data, vue) {
    return axios.post(this.liderazgoCreate, data)
  }
  postConfirmacionProcesoCreate(data, vue) {
    return axios.post(this.confirmacionProcesoCreate, data)
  }
  deleteReportesAll(id) {
    return axios.delete(this.deleteReportes + "/" + id)
  }
}