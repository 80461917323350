
import ApiServices from "../apiServices";

import axios from '@axios'


export default class ManufacturaApiServices extends ApiServices {
  constructor() {
    super("/manufactura");

    this.historicoSkusConfig = this.baseUrl + "/historicoSkus/config"
    this.historicoSkusData = this.baseUrl + "/historicoSkus/data"
    this.historicoSkusEdit = this.baseUrl + "/historicoSkus/edit"
    this.skusCreate = this.baseUrl + "/skus/create"
    this.skusDelete = this.baseUrl + "/skus/delete"

    /* NuevaDemandaForm */
    this.skusIndex = this.baseUrl + "/skus/index"
    this.skusCreate = this.baseUrl + "/skus/create"

    this.historicoOrdersConfig = this.baseUrl + "/orders/historico/config"
    this.historicoOrdersData = this.baseUrl + "/orders/historico/data"
    this.historicoOrdersEdit = this.baseUrl + "/orders/historico/edit"

    this.orderCreate = this.baseUrl + "/orders/create"
    this.orderDelete = this.baseUrl + "/orders/delete"

    this.historicoLineasProductivasConfig = this.baseUrl + "/historicoLineasProductivas/config"
    this.historicoLineasProductivasData = this.baseUrl + "/historicoLineasProductivas/data"
    this.historicoLineasProductivasEdit = this.baseUrl + "/historicoLineasProductivas/edit"

    this.lineasCreate = this.baseUrl + "/lineas/create"
    this.lineasIndex = this.baseUrl + "/lineas/index"
    this.lineasDelete = this.baseUrl + "/lineas/delete"

    this.settingsPhasingEntregaTable = this.baseUrl + "/settings/phasingEntregaTable";
    this.settingsMatrizCambiosTable = this.baseUrl + "/settings/matrizCambiosTable";

    this.historicoPlanificacionesConfig = this.baseUrl + "/historicoPlanificaciones/config"
    this.historicoPlanificacionesData = this.baseUrl + "/historicoPlanificaciones/data"
    this.historicoPlanificacionesEdit = this.baseUrl + "/historicoPlanificaciones/edit"

    this.models = {
      create_invocation: this.baseUrl + '/models/create_invocation',
      index: this.baseUrl + '/models/index',

      update_model_objective_function: this.baseUrl + '/models/update_model_objective_function',
      create_model_objective_function: this.baseUrl + '/models/create_model_objective_function',

    }

    this.invocations = {

      readNotFinished: this.baseUrl + '/invocations/readNotFinished',
      readByIds: this.baseUrl + '/invocations/readByIds',
      read: this.baseUrl + '/invocations/read/',

      download: this.baseUrl + '/invocations/download/',

      comments: {

        create: this.baseUrl + '/invocations/comments/create/',

        historico: {
          config: this.baseUrl + "/invocations/comments/historico/config",
          data: this.baseUrl + "/invocations/comments/historico/data"
        }

      },

      tags: {

        add: this.baseUrl + '/invocations/tags/add/',
        index: this.baseUrl + '/invocations/tags/index/'
      }

    }

    this.nuevaPlanificacion = {
      orders: {

        config: this.baseUrl + "/nuevaPlanificacion/orders/config",
        data: this.baseUrl + "/nuevaPlanificacion/orders/data"
      },

      lineas: {

        config: this.baseUrl + "/nuevaPlanificacion/lineas/config",
        data: this.baseUrl + "/nuevaPlanificacion/lineas/data"
      }
    }
    this.compareOptimizations = {
      compareOptimizationsMetrics: this.baseUrl + "/compareOptimizations/compareOptimizationsMetrics",
      compareOptimizationsMetricsTable: this.baseUrl + "/compareOptimizations/compareOptimizationsMetricsTable",
      compareOptimizationsMetricsParallel: this.baseUrl + "/compareOptimizations/compareOptimizationsMetricsParallel",
      compareAllOptimizationsMetricsParallel: this.baseUrl + "/compareOptimizations/compareAllOptimizationsMetricsParallel",

      compareOptimizationsFOB: this.baseUrl + "/compareOptimizations/compareOptimizationsFOBTable",
    }
  }

  postSkusCreate(data, vue) {
    return axios.post(this.skusCreate, data)
  }
  postSkusDelete(data, vue) {
    return axios.post(this.skusDelete, data)
  }

  getSkus(vue) {
    return axios.get(this.skusIndex);
  }

  postOrderCreate(data, vue) {
    return axios.post(this.orderCreate, data)
  }

  postOrderDelete(data, vue) {
    return axios.post(this.orderDelete, data)
  }

  postLineasCreate(data, vue) {
    return axios.post(this.lineasCreate, data)
  }
  
  getInvocationDetails(invocation_id, vue) {
		return axios.get(this.invocations.read + invocation_id + "/details")

	}
}

