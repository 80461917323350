import axios from '@axios'

import ApiServices from "../apiServices";

export default class PfApiServices extends ApiServices {
  constructor() {
    super("/pf");

    this.asociarLotesBrunaConfig = this.baseUrl + "/asociarLotes/bruna/config"
    this.asociarLotesBrunaData = this.baseUrl + "/asociarLotes/bruna/data"
    this.asociarLotesBrunaEdit = this.baseUrl + "/asociarLotes/bruna/edit"
    this.asociarLotesQadConfig = this.baseUrl + "/asociarLotes/qad/config"
    this.asociarLotesQadData = this.baseUrl + "/asociarLotes/qad/data"
    this.asociarLotesQadEdit = this.baseUrl + "/asociarLotes/qad/edit"
		this.asociarLotesBrunaDelete = this.baseUrl + "/asociarLotes/bruna/eliminar"


    this.historicoLineasProductivasConfig = this.baseUrl + "/historicoLineasProductivas/config"
    this.historicoLineasProductivasData = this.baseUrl + "/historicoLineasProductivas/data"
    this.historicoLineasProductivasEdit = this.baseUrl + "/historicoLineasProductivas/edit"

    this.historicoDemandaStatistics = this.baseUrl + "/historicoDemanda/statistics"
		this.historicoDemandaEdit = this.baseUrl + "/historicoDemanda/edit"
    this.historicoDemandaDelete = this.baseUrl + "/historicoDemanda/eliminar"

    this.restriccionesSkuConfig = this.baseUrl + "/restriccionesSku/config"
    this.restriccionesSkuData = this.baseUrl + "/restriccionesSku/data"
    this.restriccionesSkuEdit = this.baseUrl + "/restriccionesSku/edit"

    this.ajustesRestriccionesSkuConfig = this.baseUrl + "/ajustesRestriccionesSku/config"
    this.ajustesRestriccionesSkuData = this.baseUrl + "/ajustesRestriccionesSku/data"
    this.ajustesRestriccionesSkuEdit = this.baseUrl + "/ajustesRestriccionesSku/edit"


    this.planificacionStockData = this.baseUrl + "/planificacionStock/data"
    this.planificacionStockConfig = this.baseUrl + "/planificacionStock/config"
    this.planificacionStockEdit = this.baseUrl + "/planificacionStock/edit"

    this.StockSkuCreate = this.baseUrl + "/stockSkus/create"
    this.StockSkuData = this.baseUrl + "/stockSkus/data"
    this.StockSkuConfig = this.baseUrl + "/stockSkus/config"
    this.StockSkuEdit = this.baseUrl + "/stockSkus/edit"
    this.StockSkuDelete = this.baseUrl + "/stockSkus/eliminar"

    this.demandaConfig = this.baseUrl + "/blending/planificacion/nueva/demanda/config"
    this.demandaData = this.baseUrl + "/blending/planificacion/nueva/demanda/data"

    this.ingresoContratosEdit = this.baseUrl + "/ingresoContratos/edit"
    this.lineasCreate = this.baseUrl + "/lineas/create"
    this.planificacionCreate = this.baseUrl + "/planificacion/create"

    this.detallePlanificacionPfChart = this.baseUrl + "/detallePlanificacion/chart"
    this.detallePlanificacionTablePfConfig = this.baseUrl + "/detallePlanificacion/table/config"
    this.detallePlanificacionTablePfData = this.baseUrl + "/detallePlanificacion/table/data"
    this.detallePlanificacionTablePfEdit = this.baseUrl + "/detallePlanificacion/table/edit"

    this.detallesPlanificacion = this.baseUrl + "/detallesPlanificacion/detalle"

		this.globalHistoricoChart1 = this.baseUrl + "/globalHistorico/chart1"
		this.globalHistoricoChart2 = this.baseUrl + "/globalHistorico/chart2"
		this.globalHistoricoStatistics1 = this.baseUrl + "/globalHistorico/statistics1"
		this.globalHistoricoStatistics2 = this.baseUrl + "/globalHistorico/statistics2"


		this.detallesSemanalStatics = this.baseUrl + "/detallesPlanificacionSemanal/statistics"
    this.detalleSemanalConfig = this.baseUrl + "/detallesPlanificacionSemanal/config"
		this.detalleSemanalData = this.baseUrl + "/detallesPlanificacionSemanal/data" 

    this.matchContratosStatics = this.baseUrl + "/detallesPlanificacionMatch/statistics"
		this.matrizSatisfaccionConfig = this.baseUrl + "/detallesPlanificacionMatch/config"
		this.matizSatisfaccionData = this.baseUrl + "/detallesPlanificacionMatch/data"

    /* Match Demanda - Ver Detalle */
    this.detalleMatchContratosConfig = this.baseUrl + "/detalleMatchContratos/config"
    this.detalleMatchContratosData = this.baseUrl + "/detalleMatchContratos/data"
    this.detalleMatchContratosEdit = this.baseUrl + "/detalleMatchContratos/edit"
    this.detalleMatchContratosAsignadosConfig = this.baseUrl + "/detalleMatchContratosAsignados/config"
		this.detalleMatchContratosAsignadosData = this.baseUrl + "/detalleMatchContratosAsignados/data"
		this.detalleMatchContratosAsignadosEdit = this.baseUrl + "/detalleMatchContratosAsignados/edit"

    this.operacionesCreate = this.baseUrl + "/operaciones/create"
    this.materiaPrimaCreate = this.baseUrl + "/asociarLotes/bruna/create"

		this.materiasPrimasIndex = this.baseUrl + "/materiasPrimas/index"

    this.historicoMateriaPrimaPfStatistics = this.baseUrl + "/data/materiaprima/statistics"

    this.compareRecipes = {
      compareRecipesMetrics: this.baseUrl + "/compareRecipes/compareRecipesMetrics",
      compareRecipesMetricsSodio: this.baseUrl + "/compareRecipes/compareRecipesMetricsSodio",
      compareRecipesMetricsNutricional: this.baseUrl + "/compareRecipes/compareRecipesMetricsNutricional",
      compareRecipesMetricsTable: this.baseUrl + "/compareRecipes/compareRecipesMetricsTable",
      compareRecipesMetricsParallel: this.baseUrl + "/compareRecipes/compareRecipesMetricsParallel",
      compareRecipesMetricsParallelNutricional: this.baseUrl + "/compareRecipes/compareRecipesMetricsParallelNutricional",

    }

    this.aditivosCreate = this.baseUrl + "/data/aditivos/create",
    this.aditivosDelete = this.baseUrl + "/data/aditivos/delete"
    this.historicoAditivosConfig = this.baseUrl + "/data/aditivos/historico/config",
    this.historicoAditivosData = this.baseUrl + "/data/aditivos/historico/data",
    this.historicoAditivosEdit= this.baseUrl + "/data/aditivos/historico/edit",

    this.ajustesRestriccionesSkuAditivosConfig = this.baseUrl + "/ajustesRestricciones/aditivos/config"
    this.ajustesRestriccionesSkuAditivosData = this.baseUrl + "/ajustesRestricciones/aditivos/data"
    this.ajustesRestriccionesSkuAditivosEdit = this.baseUrl + "/ajustesRestricciones/aditivos/edit"


    this.aditivosConfig = this.baseUrl + "/blending/planificacion/nueva/aditivos/config"
    this.aditivosData = this.baseUrl + "/blending/planificacion/nueva/aditivos/data"


    this.downloadRecetaConfig = this.baseUrl +  "/blending/planificacion/historico/detalle/receta/download/config"
    this.downloadRecetaData = this.baseUrl +  "/blending/planificacion/historico/detalle/receta/download/data"

    this.recetasTable = this.baseUrl + "/blending/planificacion/historico/detalle/table"
    this.recetasResumeChart = this.baseUrl + "/blending/planificacion/historico/detalle/chart"
    this.recetasResumeChartSpider = this.baseUrl + "/blending/planificacion/historico/detalle/chartspider"

    this.recetaData = this.baseUrl + "/blending/planificacion/historico/detalle/receta/data"


  }

  postAditivosCreate(data, vue) {
    return axios.post(this.aditivosCreate, data)
  }

  postLineasCreate(data, vue) {
    return axios.post(this.lineasCreate, data)
  }
  postPlanificacionCreate(data, vue) {
    return axios.post(this.planificacionCreate, data)
  }

  postOperacionesCreate(data, vue) {
		return axios.post(this.operacionesCreate, data)
	}

  postMmppCreate(data, vue) {
		return axios.post(this.materiaPrimaCreate, data)

	}

  postStockSkuCreate(data, vue) {
		return axios.post(this.StockSkuCreate, data)
	}

	getDetallesPlanificacionRecipe(id) {
		return axios.get(this.detallesPlanificacionRecipeData + "/" + id);

	}
  getMmpp(vue) {
		return axios.get(this.materiasPrimasIndex);

	}

  deleteMMPP(id) {
		return axios.delete(this.asociarLotesBrunaDelete + "/" + id);
	}
  deleteDemanda(id) {
		return axios.delete(this.historicoDemandaDelete + "/" + id);
	}
  deleteStockSku(id) {
		return axios.delete(this.StockSkuDelete + "/" + id);
	}

  deleteAditivo(id) {
		return axios.delete(this.aditivosDelete + "/" + id);
	}
}
