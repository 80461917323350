
import ApiServices from "../apiServices";
import axios from '@axios' 

export default class CaniasApiServices extends ApiServices 
{
    constructor() {
        super("/canias");

        this.modelsRead2 = this.baseUrl + "/models/read"

        this.modelsCreateInvocationUrl = this.baseUrl + "/models/create_invocation"
        this.modelsLastInvocationUrl = this.baseUrl + "/models/last_invocation"


        this.historicoInvocationsConfig = this.baseUrl + "/historicoInvocations/config"
        this.historicoInvocationsData = this.baseUrl + "/historicoInvocations/data"

        this.historicoOptimizationsConfig = this.baseUrl + "/historicoOptimizations/config"
        this.historicoOptimizationsData = this.baseUrl + "/historicoOptimizations/data"


        this.historicoAutomationResultsConfig = this.baseUrl + "/historicoAutomationResults/config"
        this.historicoAutomationResultsData = this.baseUrl + "/historicoAutomationResults/data"


        this.realVsPrediccionTable2Config = this.baseUrl + "/realVsPrediccionTable2/config"
        this.realVsPrediccionTable2Data = this.baseUrl + "/realVsPrediccionTable2/data"

        this.historicoPredicciones = {

            stats00: this.baseUrl + "/historicoPredicciones/stats00",

            matrizRendimientoPorFundoChart00: this.baseUrl + "/historicoPredicciones/matrizRendimientoPorFundoChart00",
            rendimientoPromedioPorFundoChart00: this.baseUrl + "/historicoPredicciones/rendimientoPromedioPorFundoChart00",

            rendimientoPorEquipoChart00: this.baseUrl + "/historicoPredicciones/rendimientoPorEquipoChart00",
            rendimientoPorEquipoChart01: this.baseUrl + "/historicoPredicciones/rendimientoPorEquipoChart01",


            table00Config: this.baseUrl + "/historicoPredicciones/table00/config",
            table00Data: this.baseUrl + "/historicoPredicciones/table00/data",
        }



        this.detalleTurno = {

            stats00: this.baseUrl + "/detalleTurno/stats00",
            evolucionVariablesChart00: this.baseUrl + "/detalleTurno/evolucionVariablesChart00",
            evolucionVariablesChart01: this.baseUrl + "/detalleTurno/evolucionVariablesChart01",

        }



        this.historicoData = {

            search: this.baseUrl + "/historicoData/search",

            biometrias: {

                historicoConfig: this.baseUrl + "/historicoData/biometrias/historico/config",
                historicoData: this.baseUrl + "/historicoData/biometrias/historico/data",
    
                chart00: this.baseUrl + "/historicoData/biometrias/chart00",
    
                stats00: this.baseUrl + "/historicoData/biometrias/stats00",

                // reporte
                reporteConfig: this.baseUrl + "/historicoData/biometrias/reporte/config",
                reporteData: this.baseUrl + "/historicoData/biometrias/reporte/data",
            },

            riego: {

                historicoConfig: this.baseUrl + "/historicoData/riego/historico/config",
                historicoData: this.baseUrl + "/historicoData/riego/historico/data",
    
                chart00: this.baseUrl + "/historicoData/riego/chart00",
    
                stats00: this.baseUrl + "/historicoData/riego/stats00",
                reporteConfig: this.baseUrl + "/historicoData/riego/reporte/config",
                reporteData: this.baseUrl + "/historicoData/riego/reporte/data",
            },

            coeficienteUniformidad: {

                historicoConfig: this.baseUrl + "/historicoData/coeficienteUniformidad/historico/config",
                historicoData: this.baseUrl + "/historicoData/coeficienteUniformidad/historico/data",
    
                chart00: this.baseUrl + "/historicoData/coeficienteUniformidad/chart00",
    
                stats00: this.baseUrl + "/historicoData/coeficienteUniformidad/stats00",

                reporteConfig: this.baseUrl + "/historicoData/coeficienteUniformidad/reporte/config",
                reporteData: this.baseUrl + "/historicoData/coeficienteUniformidad/reporte/data",
            },
            
            fertilizacion: {

                historicoConfig: this.baseUrl + "/historicoData/fertilizacion/historico/config",
                historicoData: this.baseUrl + "/historicoData/fertilizacion/historico/data",
    
                chart00: this.baseUrl + "/historicoData/fertilizacion/chart00",
    
                stats00: this.baseUrl + "/historicoData/fertilizacion/stats00",

                reporteConfig: this.baseUrl + "/historicoData/fertilizacion/reporte/config",
                reporteData: this.baseUrl + "/historicoData/fertilizacion/reporte/data",
            },            

            precosechas: {

                historicoConfig: this.baseUrl + "/historicoData/precosechas/historico/config",
                historicoData: this.baseUrl + "/historicoData/precosechas/historico/data",
    
                chart00: this.baseUrl + "/historicoData/precosechas/chart00",
    
                stats00: this.baseUrl + "/historicoData/precosechas/stats00",
                reporteConfig: this.baseUrl + "/historicoData/precosechas/reporte/config",
                reporteData: this.baseUrl + "/historicoData/precosechas/reporte/data",
            },  


            maestroparcelas: {

                historicoConfig: this.baseUrl + "/historicoData/maestroparcelas/historico/config",
                historicoData: this.baseUrl + "/historicoData/maestroparcelas/historico/data",
    
                chart00: this.baseUrl + "/historicoData/maestroparcelas/chart00",
    
                stats00: this.baseUrl + "/historicoData/maestroparcelas/stats00",

                reporteConfig: this.baseUrl + "/historicoData/maestroparcelas/reporte/config",
                reporteData: this.baseUrl + "/historicoData/maestroparcelas/reporte/data",
            },              

            clima: {

                historicoConfig: this.baseUrl + "/historicoData/clima/historico/config",
                historicoData: this.baseUrl + "/historicoData/clima/historico/data",
    
                chart00: this.baseUrl + "/historicoData/clima/chart00",
    
                stats00: this.baseUrl + "/historicoData/clima/stats00",

                //reporte
                reporteConfig: this.baseUrl + "/historicoData/clima/reporte/config",
                reporteData: this.baseUrl + "/historicoData/clima/reporte/data",
            },  


       }


        this.historicoCompareRealVsPrediccionTable = this.baseUrl + "/compareRealPrediccion"
        this.historicoCompareRealVsPrediccionStats = this.baseUrl + "/compareRealPrediccionStats"
        this.quartilesAsertividadChart00 = this.baseUrl + "/quartilesAsertividadChart00"
        this.asertividadPorMesChart00 = this.baseUrl + "/asertividadPorMesChart00"

        
        this.cv12Stats = this.baseUrl + "/comparePredictions/cv12Stats"
        this.cv14Stats = this.baseUrl + "/comparePredictions/cv14Stats"

        this.cv12PredictionsChart = this.baseUrl + "/comparePredictions/cv12PredictionsChart"
        this.cv14PredictionsChart = this.baseUrl + "/comparePredictions/cv14PredictionsChart"

        this.detalleOptimizacion = {

            detalleOptimizacionTable: this.baseUrl + "/detalleOptimization/detalleOptimizacionTable",
            detalleOptimizacionTable2: this.baseUrl + "/detalleOptimization/detalleOptimizacionTable2",
            detalleOptimizacionParallelChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionParallelChart",

            detalleOptimizacionStats: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats",
            detalleOptimizacionStats2: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats2",
            detalleOptimizacionStats3: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats3",

            detalleOptimizacion8HorasStats: this.baseUrl + "/detalleOptimization/detalleOptimizacion8HorasStats",
            detalleOptimizacion8HorasStats2: this.baseUrl + "/detalleOptimization/detalleOptimizacion8HorasStats2",
            detalleOptimizacion8HorasStats3: this.baseUrl + "/detalleOptimization/detalleOptimizacion8HorasStats3",



            detalleOptimizacionGensChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionGensChart",


            detalleOptimizacionMezclaChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezclaChart",
            detalleOptimizacionMezclaChart2: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezclaChart2",
            detalleOptimizacionMezclaChart3: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezclaChart3",

            detalleOptimizacionMezcla8HorasChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezcla8HorasChart",
            detalleOptimizacionMezcla8HorasChart2: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezcla8HorasChart2",
            detalleOptimizacionMezcla8HorasChart3: this.baseUrl + "/detalleOptimization/detalleOptimizacionMezcla8HorasChart3",
            

            detalleOptimizacionDownloadExcel: this.baseUrl + "/detalleOptimization/detalleOptimizacionDownloadExcel"
            

        };



 
        this.historicoPrediccionesMezclas = {

            historicoPrediccionesMezclasStats: this.baseUrl + "/historicoPrediccionesMezclas/historicoPrediccionesMezclasStats",


            
             

        };



        this.predictions = {

            CV12LastPredictionFetChart: this.baseUrl + "/predictions/CV12LastPredictionFetChart",
            CV12LastPredictionFeDTTChart: this.baseUrl + "/predictions/CV12LastPredictionFeDTTChart",
            CV12LastPredictionFeMagChart: this.baseUrl + "/predictions/CV12LastPredictionFeMagChart",
            
            
            CV14LastPredictionFetChart: this.baseUrl + "/predictions/CV14LastPredictionFetChart",
            CV14LastPredictionFeDTTChart: this.baseUrl + "/predictions/CV14LastPredictionFeDTTChart",
            CV14LastPredictionFeMagChart: this.baseUrl + "/predictions/CV14LastPredictionFeMagChart",




            statisticsCV12: this.baseUrl + "/predictions/statisticsCV12",
            statisticsCV14: this.baseUrl + "/predictions/statisticsCV14",
        }




        this.compareInvocations = {
            compareInvocationsMetrics: this.baseUrl + "/compareInvocations/compareInvocationsMetrics",

            compareInvocationsMetricsTable: this.baseUrl + "/compareInvocations/compareInvocationsMetricsTable",


            compareInvocationsMetricsParallel: this.baseUrl + "/compareInvocations/compareInvocationsMetricsParallel",
            compareAllInvocationsMetricsParallel: this.baseUrl + "/compareInvocations/compareAllInvocationsMetricsParallel",


        }
       

        this.compareOptimizations = {
            compareOptimizationsMetrics: this.baseUrl + "/compareOptimizations/compareOptimizationsMetrics",
            compareOptimizationsMetricsTable: this.baseUrl + "/compareOptimizations/compareOptimizationsMetricsTable",
            compareOptimizationsMetricsParallel: this.baseUrl + "/compareOptimizations/compareOptimizationsMetricsParallel",
            compareAllOptimizationsMetricsParallel: this.baseUrl + "/compareOptimizations/compareAllOptimizationsMetricsParallel",

        }
       

        this.comparePredictions = {
            comparePredictionsMetrics: this.baseUrl + "/comparePredictions/comparePredictionsMetrics",
            comparePredictionsMetrics2: this.baseUrl + "/comparePredictions/comparePredictionsMetrics2",
            comparePredictionsMetricsTable: this.baseUrl + "/comparePredictions/comparePredictionsMetricsTable",

        }

    }

    modelsRead(data, vue) {
        return axios.post(this.modelsRead2, data)

    }


  }

  
