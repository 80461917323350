

export default [
  {
    header: "Modules"
  },
  {
    icon: "HomeIcon",
    title: "Home",
    children:
      [

        {
          icon: "CircleIcon",
          title: "Inicio",
          route:
          {
            name: "sqm-home",
          }
        },

      ]
  },
  {
    icon: "DatabaseIcon",
    title: "Data",
    children:
      [

        {
          icon: "CircleIcon",
          title: "Carga de Archivos",
          route:
          {
            name: "sqm-data-carga",
          }
        },
        {
          icon: "CircleIcon",
          title: "Reportes",
          route:
          {
            name: "sqm-data-reportes",
          }
        },
      ]
  },
  {
    icon: "TrendingUpIcon",
    title: "Predicciones",
    children:
      [
        {
          icon: "CircleIcon",
          title: "Predicciones Accidentabilidad",
          route:
          {
            name: "sqm-predicciones-accidentabilidad",
          }
        }
      ]
  },
 /*  {
    icon: "HelpCircleIcon",
    title: "Ayuda",
    children:
      [
        {
          icon: "CircleIcon",
          title: "FAQ",
          route:
          {
            name: "sqm-faq",
          }
        },


        {
          icon: "CircleIcon",
          title: "Registro de Actividad",
          route:
          {
            name: "not-active",
          }
        },
      ]
  }, */
    {
    icon: "TrendingUpIcon",
    title: "Demo Mejoras",
    children:
      [
        {
          icon: "TrendingUpIcon",
          title: "Predicciones",
          children: 
          [
            {
              icon: "CircleIcon",
              title: "Predicciones de Accidentabilidad",
              route:
              {
                name: "sqm-demo-predicciones-accidentabilidad",
              }
            }
          ]
        }
      ]
  }
]
