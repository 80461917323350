import axios from '@axios' 
var qs = require('qs');


export default class ApiServices {

	
    constructor(client_prefix) {


        this.baseUrl = process.env.VUE_APP_BACKEND_API_SERVICES_URL + "/api" + client_prefix

        this.clientsMe = this.baseUrl + "/clients/me"
    
        this.historicoCargaConfig = this.baseUrl + "/historicoCarga/config"
        this.historicoCargaData = this.baseUrl + "/historicoCarga/data"
        this.historicoCargaStatistics = this.baseUrl + "/historicoCarga/statistics"

    
    
        this.historicoPreciosConfig = this.baseUrl + "/historicoPrecios/config"
        this.historicoPreciosData  = this.baseUrl + "/historicoPrecios/data"
    
        this.historicoSkusConfig = this.baseUrl + "/historicoSkus/config"
        this.historicoSkusData = this.baseUrl + "/historicoSkus/data"
        this.historicoSkusEdit = this.baseUrl + "/historicoSkus/edit"
    
        this.documentsUploadDocument = this.baseUrl + "/documents/uploadDocument"
        this.documentsDownloadTemplate = this.baseUrl + "/documents/downloadTemplate"
     
        this.preciosBrasilCreate = this.baseUrl + "/preciosBrasil/create"
        
        this.contratosCreate = this.baseUrl + "/contratos/create"
        this.contratosRebajar = this.baseUrl + "/contratos/rebajar"
    
    
        this.forecastCreate = this.baseUrl + "/forecast/create"


        this.skusIndex = this.baseUrl + "/skus/index"
        this.skusCreate = this.baseUrl + "/skus/create"
    
        this.buyersIndex = this.baseUrl + "/buyers/index"
        this.buyersCreate = this.baseUrl + "/buyers/create"
        this.buyersDelete = this.baseUrl + "/buyers/delete"
    
        this.countriesIndex = this.baseUrl + "/countries/index"
    
        this.freightsIndex = this.baseUrl + "/freights/index"
        this.freightsCreate = this.baseUrl + "/freights/create"
        this.freightsDelete = this.baseUrl + "/freights/delete"
    
        this.lineasCreate = this.baseUrl + "/lineas/create"
        this.lineasIndex = this.baseUrl + "/lineas/index"
        this.lineasDelete = this.baseUrl + "/lineas/delete"
    
    
        this.preciosNoruegaCreate = this.baseUrl + "/preciosNoruega/create"
    
        this.preciosUsaCreate = this.baseUrl + "/preciosUSA/create" 
    
    
        this.historicoFreightsConfig = this.baseUrl + "/historicoFreights/config"
        this.historicoFreightsData = this.baseUrl + "/historicoFreights/data"
        this.historicoFreightsEdit = this.baseUrl + "/historicoFreights/edit"
    
    
        this.historicoBuyersConfig = this.baseUrl + "/historicoBuyers/config"
        this.historicoBuyersData = this.baseUrl + "/historicoBuyers/data"
        this.historicoBuyersEdit = this.baseUrl + "/historicoBuyers/edit"
    
        this.predictionsLast = this.baseUrl + "/predictions/last";
    
    
        this.historicoDemandaConfig = this.baseUrl + "/historicoDemanda/config"
        this.historicoDemandaData = this.baseUrl + "/historicoDemanda/data" 
        this.historicoDemandaDelete = this.baseUrl + "/historicoDemanda/delete" 
    
    
        this.historicoPrediccionesConfig = this.baseUrl + "/historicoPredicciones/config"
        this.historicoPrediccionesData = this.baseUrl + "/historicoPredicciones/data"
        this.historicoPrediccionesEdit = this.baseUrl + "/historicoPredicciones/edit" 
        this.historicoPrediccionesStatistics = this.baseUrl + "/historicoPredicciones/statistics"

    
    
        this.historicoNotificacionesConfig = this.baseUrl + "/historicoNotificaciones/config"
        this.historicoNotificacionesData = this.baseUrl + "/historicoNotificaciones/data"
     
    
     
    
    
    
        this.historicoCagesConfig = this.baseUrl + "/historicoCages/config"
        this.historicoCagesData = this.baseUrl + "/historicoCages/data"
        this.historicoCagesEdit = this.baseUrl + "/historicoCages/edit" 
    
        this.historicoPlanificacionesConfig = this.baseUrl + "/historicoPlanificaciones/config"
        this.historicoPlanificacionesData = this.baseUrl + "/historicoPlanificaciones/data"
        this.historicoPlanificacionesEdit = this.baseUrl + "/historicoPlanificaciones/edit" 
    
        this.historicoPlanificacionesStatistics = this.baseUrl + "/historicoPlanificaciones/statistics"




    
    
        this.stockJaulasChart00 = this.baseUrl + "/stockJaulas/chart0" 
        this.stockJaulasChart01 = this.baseUrl + "/stockJaulas/chart1" 
        this.stockJaulasChart02 = this.baseUrl + "/stockJaulas/chart2" 
    
        this.stockJaulasCentros = this.baseUrl + "/stockJaulas/centros" 
    
    
        this.detallePrediccionChart = this.baseUrl + "/detallePrediccion/chart"
        this.detallePrediccionTableConfig = this.baseUrl + "/detallePrediccion/table/config"
        this.detallePrediccionTableData = this.baseUrl + "/detallePrediccion/table/data"
        this.detallePrediccionTableEdit = this.baseUrl + "/detallePrediccion/table/edit"
     
    
        this.demandaIrrestrictaMonthsCategories = this.baseUrl + "/demandaIrrestricta/monthsCategories";
        this.demandaIrrestrictaChart00 = this.baseUrl + "/demandaIrrestricta/chart0";
        this.demandaIrrestrictaChart01 = this.baseUrl + "/demandaIrrestricta/chart1";
        this.demandaIrrestrictaChart02 = this.baseUrl + "/demandaIrrestricta/chart2";
        this.demandaIrrestrictaChart03 = this.baseUrl + "/demandaIrrestricta/chart3";
        this.demandaIrrestrictaChart04 = this.baseUrl + "/demandaIrrestricta/chart4";
    
        this.demandaIrrestrictaChart05 = this.baseUrl + "/demandaIrrestricta/chart5";
     
        this.demandaIrrestrictaTable00 = this.baseUrl + "/demandaIrrestricta/table0";
       
        this.demandaIrrestrictaTable01 = this.baseUrl + "/demandaIrrestricta/table1";
    
    
        this.historicoLineasProductivasConfig = this.baseUrl + "/historicoLineasProductivas/config"
        this.historicoLineasProductivasData = this.baseUrl + "/historicoLineasProductivas/data"
        this.historicoLineasProductivasEdit = this.baseUrl + "/historicoLineasProductivas/edit"
    
    
        this.settingsLast = this.baseUrl + "/settings/last";
        this.settingsUpdateLast = this.baseUrl + "/settings/updateLast";
        

    
        //Detalle Planificacion
    
        this.ajustesCosechaProduccion = {
    
            porcentajeDegradacionMensual: this.baseUrl + "/ajustesCosechaProduccion/procentajeDegradacionMensual",
            saveAjustesProduccion: this.baseUrl + "/ajustesCosechaProduccion/saveAjustesProduccion"
    
    
        }

        this.runs = {

            create: this.baseUrl + "/runs/create"

        }
    
    
        this.home = {
    
            resumen0: this.baseUrl + "/home/resumen0",
            resumen1: this.baseUrl + "/home/resumen1",
            resumen2: this.baseUrl + "/home/resumen2",
            resumen3: this.baseUrl + "/home/resumen3",
            resumen4: this.baseUrl + "/home/resumen4",
    
    
        }
    
    
    
        this.detallePlanificacion = {
    
            planCosecha: {
    
                centrosNames: this.baseUrl + "/detallePlanificacion/planCosecha/centrosNames",
                distribucionCosechaPorCentroChart: this.baseUrl + "/detallePlanificacion/planCosecha/distribucionCosechaPorCentroChart",
                distribucionCosechaPorEspecieChart: this.baseUrl + "/detallePlanificacion/planCosecha/distribucionCosechaPorEspecieChart",
                mortalidadYPesoPromedioChart: this.baseUrl + "/detallePlanificacion/planCosecha/mortalidadYPesoPromedioChart",
    
                estadisticas: this.baseUrl + "/detallePlanificacion/planCosecha/estadisticas",
                jaulasCosechadas: this.baseUrl + "/detallePlanificacion/planCosecha/jaulasCosechadas",
    
                tableTop10Mortalidad: this.baseUrl + "/detallePlanificacion/planCosecha/tableTop10Mortalidad",
                tableTop10PesoPlanta: this.baseUrl + "/detallePlanificacion/planCosecha/tableTop10PesoPlanta"
                
    
            },
    
            detalleJaula: {
    
                centrosNames: this.baseUrl + "/detallePlanificacion/detalleJaula/centrosNames",
                estadisticas: this.baseUrl + "/detallePlanificacion/detalleJaula/estadisticas",
                mortalidadYPesoPromedioChart: this.baseUrl + "/detallePlanificacion/detalleJaula/mortalidadYPesoPromedioChart",
    
                distribucionCalibresKgWFEMensualChart: this.baseUrl + "/detallePlanificacion/detalleJaula/distribucionCalibresKgWFEMensualChart",
                
                jaulasCosechadas: this.baseUrl + "/detallePlanificacion/detalleJaula/jaulasCosechadas",
    
            },
    
    
            planProductivo: {
    
                estadisticas: this.baseUrl + "/detallePlanificacion/planProductivo/estadisticas",
                costoDistribucionUSDMensualChart: this.baseUrl + "/detallePlanificacion/planProductivo/costoDistribucionUSDMensualChart",
                costoEmpaqueUSDMensualChart: this.baseUrl + "/detallePlanificacion/planProductivo/costoEmpaqueUSDMensualChart",
                costoProcesoTotalUSDMensualChart: this.baseUrl + "/detallePlanificacion/planProductivo/costoProcesoTotalUSDMensualChart",
                usoCostosMensualesLineas: this.baseUrl + "/detallePlanificacion/planProductivo/usoCostosMensualesLineas",

                tableTop10Produccion: this.baseUrl + "/detallePlanificacion/planProductivo/tableTop10Produccion",
                tableKardex: this.baseUrl + "/detallePlanificacion/planProductivo/tableKardex",
                updateTableKardex: this.baseUrl + "/detallePlanificacion/planProductivo/updateTableKardex",

                
                


                distribucionMensualKgProducidosPorTipo: this.baseUrl + "/detallePlanificacion/planProductivo/distribucionMensualKgProducidosPorTipo",
                tablaDetalleProduccion: this.baseUrl + "/detallePlanificacion/planProductivo/tablaDetalleProduccion",
    
                tablaFillrateSemanalPerCanal: this.baseUrl + "/detallePlanificacion/planProductivo/tablaFillrateSemanalPerCanal",
                tablaFillrateSemanalPerCanalReal: this.baseUrl + "/detallePlanificacion/planProductivo/tablaFillrateSemanalPerCanalReal",
                            
                consolidadoM2CanalPerFormatoChart: this.baseUrl + "/detallePlanificacion/planProductivo/consolidadoM2CanalPerFormatoChart",
                consolidadoM2CanalPerFormatoTable:  this.baseUrl + "/detallePlanificacion/planProductivo/consolidadoM2CanalPerFormatoTable",
    
                distribucionProduccionM2PerFormatoChart: this.baseUrl + "/detallePlanificacion/planProductivo/distribucionProduccionM2PerFormatoChart",
                distribucionProduccionM2PerCanalChart: this.baseUrl + "/detallePlanificacion/planProductivo/distribucionProduccionM2PerCanalChart",


                
            }
    
        }
    

		this.historicoPreciosConfig = this.baseUrl + "/historicoPrecios/config"
		this.historicoPreciosData = this.baseUrl + "/historicoPrecios/data"

		this.forecastCreate = this.baseUrl + "/forecast/create"


		this.skusIndex = this.baseUrl + "/skus/index"
		this.skusCreate = this.baseUrl + "/skus/create"

		this.buyersIndex = this.baseUrl + "/buyers/index"
		this.buyersCreate = this.baseUrl + "/buyers/create"
		this.buyersDelete = this.baseUrl + "/buyers/delete"

		this.countriesIndex = this.baseUrl + "/countries/index"

		this.freightsIndex = this.baseUrl + "/freights/index"
		this.freightsCreate = this.baseUrl + "/freights/create"
		this.freightsDelete = this.baseUrl + "/freights/delete"

		this.lineasCreate = this.baseUrl + "/lineas/create"
		this.lineasIndex = this.baseUrl + "/lineas/index"
		this.lineasDelete = this.baseUrl + "/lineas/delete"


		this.preciosNoruegaCreate = this.baseUrl + "/preciosNoruega/create"

		this.preciosUsaCreate = this.baseUrl + "/preciosUSA/create"


		this.historicoFreightsConfig = this.baseUrl + "/historicoFreights/config"
		this.historicoFreightsData = this.baseUrl + "/historicoFreights/data"
		this.historicoFreightsEdit = this.baseUrl + "/historicoFreights/edit"


		this.historicoBuyersConfig = this.baseUrl + "/historicoBuyers/config"
		this.historicoBuyersData = this.baseUrl + "/historicoBuyers/data"
		this.historicoBuyersEdit = this.baseUrl + "/historicoBuyers/edit"

		this.predictionsLast = this.baseUrl + "/predictions/last";


		this.historicoDemandaConfig = this.baseUrl + "/historicoDemanda/config"
		this.historicoDemandaData = this.baseUrl + "/historicoDemanda/data"
		this.historicoDemandaDelete = this.baseUrl + "/historicoDemanda/delete"

		this.historicoPrediccionesConfig = this.baseUrl + "/historicoPredicciones/config"
		this.historicoPrediccionesData = this.baseUrl + "/historicoPredicciones/data"
		this.historicoPrediccionesEdit = this.baseUrl + "/historicoPredicciones/edit"
		this.historicoPrediccionesStatistics = this.baseUrl + "/historicoPredicciones/statistics"



		this.historicoNotificacionesConfig = this.baseUrl + "/historicoNotificaciones/config"
		this.historicoNotificacionesData = this.baseUrl + "/historicoNotificaciones/data"






		this.historicoCagesConfig = this.baseUrl + "/historicoCages/config"
		this.historicoCagesData = this.baseUrl + "/historicoCages/data"
		this.historicoCagesEdit = this.baseUrl + "/historicoCages/edit"

		this.historicoPlanificacionesConfig = this.baseUrl + "/historicoPlanificaciones/config"
		this.historicoPlanificacionesData = this.baseUrl + "/historicoPlanificaciones/data"
		this.historicoPlanificacionesEdit = this.baseUrl + "/historicoPlanificaciones/edit"

		this.historicoPlanificacionesStatistics = this.baseUrl + "/historicoPlanificaciones/statistics"

		this.stockJaulasChart00 = this.baseUrl + "/stockJaulas/chart0"
		this.stockJaulasChart01 = this.baseUrl + "/stockJaulas/chart1"
		this.stockJaulasChart02 = this.baseUrl + "/stockJaulas/chart2"

		this.stockJaulasCentros = this.baseUrl + "/stockJaulas/centros"


		this.detallePrediccionChart = this.baseUrl + "/detallePrediccion/chart"
		this.detallePrediccionTableConfig = this.baseUrl + "/detallePrediccion/table/config"
		this.detallePrediccionTableData = this.baseUrl + "/detallePrediccion/table/data"
		this.detallePrediccionTableEdit = this.baseUrl + "/detallePrediccion/table/edit"

      
		this.demandaIrrestrictaMonthsCategories = this.baseUrl + "/demandaIrrestricta/monthsCategories";
		this.demandaIrrestrictaChart00 = this.baseUrl + "/demandaIrrestricta/chart0";
		this.demandaIrrestrictaChart01 = this.baseUrl + "/demandaIrrestricta/chart1";
		this.demandaIrrestrictaChart02 = this.baseUrl + "/demandaIrrestricta/chart2";
		this.demandaIrrestrictaChart03 = this.baseUrl + "/demandaIrrestricta/chart3";
		this.demandaIrrestrictaChart04 = this.baseUrl + "/demandaIrrestricta/chart4";

		this.demandaIrrestrictaChart05 = this.baseUrl + "/demandaIrrestricta/chart5";

		this.demandaIrrestrictaTable00 = this.baseUrl + "/demandaIrrestricta/table0";

		this.demandaIrrestrictaTable01 = this.baseUrl + "/demandaIrrestricta/table1";


		this.historicoLineasProductivasConfig = this.baseUrl + "/historicoLineasProductivas/config"
		this.historicoLineasProductivasData = this.baseUrl + "/historicoLineasProductivas/data"
		this.historicoLineasProductivasEdit = this.baseUrl + "/historicoLineasProductivas/edit"


		this.settingsLast = this.baseUrl + "/settings/last";
		this.settingsUpdateLast = this.baseUrl + "/settings/updateLast";


		//Detalle Planificacion

		this.ajustesCosechaProduccion = {

			porcentajeDegradacionMensual: this.baseUrl + "/ajustesCosechaProduccion/procentajeDegradacionMensual",
			saveAjustesProduccion: this.baseUrl + "/ajustesCosechaProduccion/saveAjustesProduccion"


		}

		this.runs = {

			create: this.baseUrl + "/runs/create"

		}


		this.logisticaMatriz = {

			matrizCostosTraslados: this.baseUrl + "/ajustesLogistica/configuracionLogisticaMatriz",
			matrizRestriccionesTraslados: this.baseUrl + "/ajustesLogistica/configuracionLogisticaMatrizRestricciones",
			saveAjustesProduccion: this.baseUrl + "/ajustesLogistica/saveAjustesLogistica"

		}

		this.home = {

			resumen0: this.baseUrl + "/home/resumen0",
			resumen1: this.baseUrl + "/home/resumen1",
			resumen2: this.baseUrl + "/home/resumen2",
			resumen3: this.baseUrl + "/home/resumen3",
			resumen4: this.baseUrl + "/home/resumen4",


		}

		this.operacionesCreate = this.baseUrl + "/operaciones/create"

		this.planificacionCreate = this.baseUrl + "/planificacion/create"


		this.invocations = {

			readNotFinished: this.baseUrl + '/invocations/readNotFinished',
            readByIds: this.baseUrl + '/invocations/readByIds',
            read: this.baseUrl + '/invocations/read/',

            download: this.baseUrl + '/invocations/download/',

			comments: {

				create: this.baseUrl + '/invocations/comments/create/',

				historico: {
					config: this.baseUrl + "/invocations/comments/historico/config",
					data: this.baseUrl + "/invocations/comments/historico/data"
				}

			},

			tags: {

				add: this.baseUrl + '/invocations/tags/add/',
				index: this.baseUrl + '/invocations/tags/index/'


			}

		}

		this.models = {
			create_invocation: this.baseUrl + '/models/create_invocation',
			index: this.baseUrl + '/models/index',

			update_model_objective_function: this.baseUrl + '/models/update_model_objective_function',
			create_model_objective_function: this.baseUrl + '/models/create_model_objective_function',

		}

	}


	getLastSetting() {
		return axios.get(this.settingsLast, {
			headers: {
				Accept: 'application/json'
			}
		})
	}

	updateLastSetting(data) {
		return axios.post(this.settingsUpdateLast, data)

	}


	getConfig() {
		let baseUrl = process.env.VUE_APP_BACKEND_API_SERVICES_URL + "/config"

		return axios.get(baseUrl)
	}

	postInvocationCreateComment(invocation_id, data) {
		return axios.post(this.invocations.comments.create + invocation_id, data)

	}

	postInvocationAddTags(invocation_id, data) {
		return axios.post(this.invocations.tags.add + invocation_id, data)

	}

	getInvocationTags(invocation_id, vue) {
		return axios.get(this.invocations.tags.index + invocation_id)

	}

	getInvocation(invocation_id, vue) {
		return axios.get(this.invocations.read + invocation_id)

	}

	getModelsIndex() {
		return axios.get(this.models.index)

	}

	postUpdateModelObjectiveFunction(data) {
		return axios.post(this.models.update_model_objective_function, data)

	}

	postCreateModelObjectiveFunction(data) {
		return axios.post(this.models.create_model_objective_function, data)

	}



	getInvocationDetails(invocation_id, vue) {
		return axios.get(this.invocations.read + invocation_id + "/details")

	}

	getReadNotFinished(vue) {
		return axios.get(this.invocations.readNotFinished)

	}

	postPreciosUsaCreate(data, vue) {
		return axios.post(this.preciosUsaCreate, data)

	}



	postPreciosNoruegaCreate(data, vue) {
		return axios.post(this.preciosNoruegaCreate, data)

	}




	postPreciosBrasilCreate(data, vue) {
		return axios.post(this.preciosBrasilCreate, data)

	}


	postUploadDocument(data, vue) {
		return axios
			.post(this.documentsUploadDocument, data,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						'Access-Control-Allow-Origin': '*'
					}

				});
	}

	postModelsCreateInvocation(data) {
		return axios.post(this.models.create_invocation, data)

	}

	getConfig() {
		let baseUrl = process.env.VUE_APP_BACKEND_API_SERVICES_URL + "/config"

		return axios.get(baseUrl)
	}

	postInvocationCreateComment(invocation_id, data) {
		return axios.post(this.invocations.comments.create + invocation_id, data)

	}

	postInvocationAddTags(invocation_id, data) {
		return axios.post(this.invocations.tags.add + invocation_id, data)

	}

	getInvocationTags(invocation_id, vue) {
		return axios.get(this.invocations.tags.index + invocation_id)

	}

	getInvocation(invocation_id, vue) {
		return axios.get(this.invocations.read + invocation_id)

	}

	getInvocationDetails(invocation_id, vue) {
		return axios.get(this.invocations.read + invocation_id + "/details")

	}

	getReadNotFinished(vue) {
		return axios.get(this.invocations.readNotFinished)

	}

	postPreciosUsaCreate(data, vue) {
		return axios.post(this.preciosUsaCreate, data)

	}



	postPreciosNoruegaCreate(data, vue) {
		return axios.post(this.preciosNoruegaCreate, data)

	}




	postPreciosBrasilCreate(data, vue) {
		return axios.post(this.preciosBrasilCreate, data)

	}


	postUploadDocument(data, vue) {
		return axios
			.post(this.documentsUploadDocument, data,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						'Access-Control-Allow-Origin': '*'
					}

				});


	}

	getDownloadFile(url) {
		return axios.get(url, {
			responseType: 'blob',
			headers: {
				'Access-Control-Allow-Origin': '*'
			}
		})
			.then((response) => {
				const contentDisposition = response.headers['content-disposition'];

				let fileName = contentDisposition.split("filename=")[1];

				console.log({ fileName })

				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', contentDisposition.split("filename=")[1]);
				document.body.appendChild(link);
				link.click();


			});

	}




	postContratosCreate(data, vue) {
		return axios.post(this.contratosCreate, data)

	}

	postLineasCreate(data, vue) {
		return axios.post(this.lineasCreate, data)

	}


	postLineasDelete(data, vue) {
		return axios.post(this.lineasDelete, data)

	}

	// Ingreso Contratos
	postIngresoContratosCreate(data, vue) {
		return axios.post(this.ingresoContratosCreate, data)

	}


	postContratosRebajar(data, vue) {
		return axios.post(this.contratosRebajar, data)

	}

	saveAjustesProduccion(data, vue) {
		return axios.post(this.ajustesCosechaProduccion.saveAjustesProduccion, data)

	}

	getRecipeSemanalData(vue) {
		return axios.post(this.detallesPlanificacionBlendingRecipeSemanalData)
	}

	postForecastCreate(data, vue) {
		return axios.post(this.forecastCreate, data)

	}

	getLastPrediction(vue) {
		return axios.get(this.predictionsLast);

	}

	getSkus(vue) {
		return axios.get(this.skusIndex);

	}

	getBuyers(vue) {
		return axios.get(this.buyersIndex);

	}

	postSkuCreate(data, vue) {
		return axios.post(this.skusCreate, data)

	}

	postBuyersCreate(data, vue) {
		return axios.post(this.buyersCreate, data)

	}
	postBuyersDelete(data, vue) {
		return axios.post(this.buyersDelete, data)

	}

	getDetallesPlanificacionRecipeData(vue) {
		return axios.post(this.detallesPlanificacionRecipeData);

	}





	postFreightsCreate(data, vue) {
		return axios.post(this.freightsCreate, data)

	}

	getCountries(vue) {
		return axios.get(this.countriesIndex);

	}

	getFreights(vue) {
		return axios.get(this.freightsIndex);

	}


	postDemandaDelete(data, vue) {
		return axios.post(this.historicoDemandaDelete, data)

	}


	postFreightDelete(data, vue) {
		return axios.post(this.freightsDelete, data)

	}

	postOperacionesCreate(data, vue) {
		return axios.post(this.operacionesCreate, data)
	}

	postPlanificacionCreate(data, vue) {
		return axios.post(this.planificacionCreate, data)
	}

	saveLogisticaMatriz(data, vue) {
		return axios.post(this.logisticaMatriz.saveAjustesProduccion, data)

	}

	getReadByIds(ids)
		{
			let query = qs.stringify({ ids: ids });

			return axios.get(this.invocations.readByIds + "?" + query)

		}


}