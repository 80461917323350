export default [
    { path: "/", redirect: { name: "admin-job-editor" } },
  

  

    {
      path: "/admin-job-editor",
      name: "admin-job-editor",
      component: () => import("@/views/clients/admin/jobs/JobEditor.vue"),
      meta: {
        pageTitle: "Job Editor",
        breadcrumb: [
          {
            text: "Job Editor",
            active: true,
          },
        ],
      },
    },



  
    
  ];
  