import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  //timeout: 0,
  //crossDomain: true,
  //headers: {
    //'Access-Control-Allow-Origin': '*',
  //}
})

Vue.prototype.$http = axiosIns

export default axiosIns
