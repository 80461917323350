export default [
    { path: "/", redirect: { name: "simulacion-escenarios" } },
  

  
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/clients/klabin/home/Home.vue"),
      meta: {
        pageTitle: "Inicio",
        breadcrumb: [
          {
            text: "Consola de Administración",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/ajustes",
      name: "ajustes",
      component: () => import("@/views/clients/klabin/ajustes/Ajustes.vue"),
      meta: {
        pageTitle: "Ajustes Generales",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },

    


    {
      path: "/optimizacion",
      name: "optimizacion",
      component: () => import("@/views/clients/klabin/optimizations/NuevaOptimizacion.vue"),
      meta: {
        pageTitle: "Inicio",
        breadcrumb: [
          { 
            text: "Optimizacion",
            active: true,
          },
        ],
      },
    },    

    {
      path: "/historico-optimizacion",
      name: "historico-optimizations",
      component: () => import("@/views/clients/klabin/historico-optimizations/HistoricoOptimizations.vue"),
      meta: {
        pageTitle: "Histórico de Optimizaciones",
        breadcrumb: [
          { 
            text: "Histórico de Optimizaciones",
            active: true,
          },
        ],
      },
    },    
    


    {
      path: "/simulacion-escenarios",
      name: "simulacion-escenarios",
      component: () =>
        import(
          "@/views/clients/klabin/simulaciones/HistoricoPlanificaciones.vue"
        ),
      meta: {
        pageTitle: "Simulación de Escenarios",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/comparacion-escenarios-all",
      name: "comparacion-escenarios-all",
      component: () =>
        import(
          "@/views/clients/klabin/simulaciones/ComparacionEscenariosAll.vue"
        ),
      meta: {
        pageTitle: "Comparación de Escenarios",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
  
    {
      path: "/simulacion-escenarios/compare-runs/",
      name: "compare-runs",
      component: () =>
        import(
          "@/views/clients/klabin/simulaciones/CompareRuns.vue"
        ),
      meta: {
        pageTitle: "Comparación de Escenarios",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/optimizations/compare-optimizations/",
      name: "compare-optimizations",
      component: () =>
        import(
          "@/views/clients/klabin/historico-optimizations/CompareOptimizations.vue"
        ),
      meta: {
        pageTitle: "Comparación de Optimizaciones",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },

    

    {
      path: "/optimizations/details/",
      name: "optimization-details",
      component: () =>
        import(
          "@/views/clients/klabin/historico-optimizations/DetailOptimization.vue"
        ),
      meta: {
        pageTitle: "Detalle de Optimización",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },
        
    {
      path: "/prediccion-quebra/",
      name: "prediccion-quebra",
      component: () =>
        import(
          "@/views/clients/klabin/prediccion-quebra/PrediccionQuebra.vue"
        ),
      meta: {
        pageTitle: "Prediccion Quebra (SAK16080 - SAK16085 - SAK16090)",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },

    {
      path: "/prediccion-anomalia/",
      name: "prediccion-anomalia",
      component: () =>
        import(
          "@/views/clients/klabin/prediccion-anomalia/PrediccionAnomalia.vue"
        ),
      meta: {
        pageTitle: "Predicción Anomalía (SAK16080 - SAK16085 - SAK16090)",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    }


  
    
  ];
  