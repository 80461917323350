export default [
  {
    path: "/",
    name: "sqm-home",
    component: () => import("@/views/clients/sqm/home/Home.vue"),
    meta: {
      pageTitle: "Inicio",
      breadcrumb: [
        {
          text: "Panel de inicio Sqm",
          active: true
        }
      ]
    }
  }, {
    path: "/sqm/not-active",
    name: "not-active",
    component: () =>
      import(
        "@/views/clients/sqm/error/NotActive.vue"
      ),
    meta: {
      pageTitle: "No activo",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/sqm/faq",
    name: "sqm-faq",
    component: () =>
      import(
        "@/views/clients/sqm/faq/Faq.vue"
      ),
    meta: {
      pageTitle: "Preguntas Frecuentes",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/sqm/data/carga",
    name: "sqm-data-carga",
    component: () =>
      import("@/views/clients/sqm/data/carga-archivo/CargaData.vue"),
    meta: {
      pageTitle: "Carga de Archivo",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  }, 
  {
    path: "/sqm/data/reportes",
    name: "sqm-data-reportes",
    component: () =>
      import("@/views/clients/sqm/data/reportes/Reportes.vue"),
    meta: {
      pageTitle: "Reportes",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/sqm/predicciones/accidentabilidad",
    name: "sqm-predicciones-accidentabilidad",
    component: () =>
      import("@/views/clients/sqm/predicciones/Predicciones.vue"),
    meta: {
      pageTitle: "Predicciones Accidentabilidad",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  }, 
  {
    path: "/sqm/demo/predicciones/accidentabilidad",
    name: "sqm-demo-predicciones-accidentabilidad",
    component: () =>
      import("@/views/clients/sqm/demo/predicciones/accidentabilidad/Predicciones.vue"),
    meta: {
      pageTitle: "Demo Predicciones Accidentabilidad",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  
];
