

export default [
    {
        header: "Modules"
    },
    {
        icon: "HomeIcon",
        title: "Home",
        children:
        [

            {
                icon: "CircleIcon",
                title: "Inicio",
                route:
                {
                    name: "blumar-home",
                }
            },
                        
/*
            {
                icon: "CircleIcon",
                title: "Dashboad Global",
                route:
                {
                    name: "blumar",
                }
            }*/
        ]
    },
    {
        icon: "DatabaseIcon",
        title: "Data",
        children:
        [

            {
                icon: "CircleIcon",
                title: "Carga de Archivo",
                route:
                {
                    name: "blumar-carga-data",
                }
            },            
         

            {
                icon: "CircleIcon",
                title: "Demanda",
                children: [ 

                    {
                        icon: "CircleIcon",
                        title: "Ingreso Demanda",
                        route:
                        {
                            name: "blumar-ingreso-contratos",
                        }
                    },                     

                    {
                        icon: "CircleIcon",
                        title: "Histórico Demanda",
                        route:
                        {
                            name: "blumar-historico-demanda",
                        }
                    },        
                    
                    {
                        icon: "CircleIcon",
                        title: "Demanda Irrestricta",
                        route:
                        {
                            name: "blumar-demanda-irrestricta",
                        }
                    },                       


                ]
            },

            {
                icon: "CircleIcon",
                title: "Materia Prima",
                children: [ 

                            
                    {
                        icon: "CircleIcon",
                        title: "Stock Jaulas",
                        route:
                        {
                            name: "blumar-stock-jaulas",
                        }
                    },

                    /*
                    {
                        icon: "CircleIcon",
                        title: "Stock Transito",
                        route:
                        {
                            name: "blumar-stock-transito",
                        }
                    },

                    {
                        icon: "CircleIcon",
                        title: "Stock Acopio",
                        route:
                        {
                            name: "blumar-stock-acopio",
                        }
                    },

                    {
                        icon: "CircleIcon",
                        title: "Compra MP",
                        route:
                        {
                            name: "blumar-compra-materia-prima",
                        }
                    },
*/
                ]
            },


            /*
            {
                icon: "CircleIcon",
                title: "Ingreso Forecast",
                route:
                {
                    name: "blumar-ingreso-forecast",
                }
            },
*/
            {
                icon: "CircleIcon",
                title: "Skus",
                route:
                {
                    name: "blumar-skus",
                }
            },

            {
                icon: "CircleIcon",
                title: "Fletes",
                route:
                {
                    name: "blumar-freights",
                }
            },
            
          

            {
                icon: "CircleIcon",
                title: "Precios",
                route:
                {
                    name: "blumar-precios",
                }
            },               



            {
                icon: "CircleIcon",
                title: "Clientes",
                route:
                {
                    name: "blumar-historico-clientes",
                }
            },        
            


            /*
            {
                icon: "CircleIcon",
                title: "Ajustes",
                route:
                {
                    name: "blumar-ajustes",
                }
            },   
*/
                        
            
        ]
    },
    {
        icon: "TrendingUpIcon",
        title: "Predicciones",
        children:
        [
            {
                icon: "CircleIcon",
                title: "Modelos",
                route:
                {
                    name: "blumar-modelos-resultados",
                }
            },
            {
                icon: "CircleIcon",
                title: "Histórico Predicciones",
                route:
                {
                    name: "blumar-historico-predicciones",
                }
            },
            /*
            {
                icon: "CircleIcon",
                title: "Simulacion de Escenarios",
                route:
                {
                    name: "blumar-simulacion-escenarios",
                }
            },
           
            {
                icon: "CircleIcon",
                title: "Comparacion de Escenarios",
                route:
                {
                    name: "blumar-comparacion-escenarios-all",
                }
            },
             */
            

        ]
    }, 
    {
        icon: "LayersIcon",
        title: "Planificaciones",
        children:
        [
            {
                icon: "CircleIcon",
                title: "Nueva Planificación",
                route:
                {
                    name: "blumar-nueva-planificacion",
                }
            },
            /*
            {
                icon: "CircleIcon",
                title: "Historico Planificación",
                route:
                {
                    name: "blumar-historico-planificaciones",
                }
            },
*/
            {
                icon: "CircleIcon",
                title: "Historico Planificación",
                route:
                {
                    name: "blumar-historico-planificaciones",
                }
            }            
        ]
    },



    
    /*
    {
        icon: "BookmarkIcon",
        title: "Acciones Rápidas",
        children:
        [
            {
                icon: "CircleIcon",
                title: "Cosecha Urgente",
                route:
                {
                    name: "blumar-cosecha-urgente",
                }
            }
        ]
    },
    */
    /*
    {
        icon: "ArchiveIcon",
        title: "Demo",
        children:
        [
            {
                icon: "CircleIcon",
                title: "Demo00",
                route:
                {
                    name: "demo-blumar-test",
                }
            },
            {
                icon: "CircleIcon",
                title: "ViewCreator",
                route:
                {
                    name: "view-creator",
                }
            }
        ]
    },
    */
    {
        icon: "SettingsIcon",
        title: "Ajustes y restricciones",
        children:
        [
            /*
            {
                icon: "CircleIcon",
                title: "Formularios y Tablas",
                route:
                {
                    name: "blumar-formularios-tablas",
                }
            },
*/
            {
                icon: "CircleIcon",
                title: "Lineas Productivas",
                route:
                {
                    name: "blumar-ajustes-lineas-productivas",
                }
            },
            {
                icon: "CircleIcon",
                title: "Degradaciones",
                route:
                {
                    name: "blumar-ajustes-cosecha-y-produccion",
                }
            },

            /*
            {
                icon: "CircleIcon",
                title: "Comerciales",
                route:
                {
                    name: "blumar-ajustes-comerciales",
                }
            },            
*/
            


            


            
        ]

        
    },

    {
        icon: "HelpCircleIcon",
        title: "Ayuda",
        children:
        [
            {
                icon: "CircleIcon",
                title: "FAQ",
                route:
                {
                    name: "blumar-faq",
                }
            },


            {
                icon: "CircleIcon",
                title: "Notificaciones",
                route:
                {
                    name: "blumar-hitorico-notificaciones",
                }
            },

         
            


        ]

    }
        




]
