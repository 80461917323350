export default [
	{
		path: "/",
		name: "pf-home",
		component: () => import("@/views/clients/pf/home/Home.vue"),
		meta: {
			pageTitle: "Inicio",
			breadcrumb: [
				{
					text: "Panel de inicio PF",
					active: true
				}
			]
		}
	},

	{
		path: "/pf/carga-data",
		name: "pf-carga-data",
		component: () =>
			import("@/views/clients/pf/carga-data/CargaData.vue"),
		meta: {
			pageTitle: "Carga de Archivo",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
	{
		path: "/pf/data/aditivos/ingreso",
		name: "pf-ingreso-aditivos",
		component: () =>
			import("@/views/clients/pf/data/aditivos/IngresoAditivos.vue"),
		meta: {
			pageTitle: "Ingreso Aditivos",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
	{
		path: "/pf/data/materia-prima/ingreso",
		name: "pf-ingreso-materia-prima",
		component: () =>
			import("@/views/clients/pf/data/materia-prima/Ingreso.vue"),
		meta: {
			pageTitle: "Ingreso Materia Prima",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
	{
		path: "/pf/data/materia-prima/ingreso-stock",
		name: "pf-ingreso-stock-materia-prima",
		component: () =>
			import("@/views/clients/pf/data/materia-prima/IngresoStock.vue"),
		meta: {
			pageTitle: "Stock Materia Prima",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
	// pf-ingreso-stock-materia-prima

	{
		path: "/pf/data/materia-prima/historico",
		name: "pf-historico-materia-prima",
		component: () =>
			import("@/views/clients/pf/data/materia-prima/Historico.vue"),
		meta: {
			pageTitle: "Histórico Materia Prima",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},

	{
		path: "/pf/carga-formularios-ingreso-demanda",
		name: "pf-ingreso-demanda",
		component: () =>
			import(
				"@/views/clients/pf/carga-formularios/IngresoDemanda.vue"
			),
		meta: {
			pageTitle: "Ingreso de Demanda",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},

	{
		path: "/pf/historico-demanda",
		name: "pf-historico-demanda",
		component: () =>
			import("@/views/clients/pf/demanda/HistoricoDemanda.vue"),
		meta: {
			pageTitle: "Historico de Demanda",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
	
  {
    path: "/pf/ajustes-y-restricciones/sku-mmpp",
    name: "pf-ajustes-sku",
    component: () => import("@/views/clients/pf/ajustes-restricciones/sku/RestriccionesSku.vue"),
    meta: {
      pageTitle: "SKU MMPP",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
	{
    path: "/pf/ajustes-y-restricciones/sku-aditivos",
    name: "pf-restricciones-aditivos",
    component: () => import("@/views/clients/pf/ajustes-restricciones/aditivos/RestriccionesSkuAditivos.vue"),
    meta: {
      pageTitle: "SKU Aditivos",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

	{
		path: "/pf/blending/nueva-planificacion",
		name: "pf-nueva-planificacion",
		component: () => import("@/views/clients/pf/blending/planificacion/NuevaPlanificacion.vue"),
		meta: {
			pageTitle: "Nueva Planificación",
			breadcrumb: [
				{
					text: "",
					active: true
				}
			]
		}
	},

	{
		path: "/pf/historico-blending/detail/:blending_id",
		name: "dashboard-pf-historico-blending-detail",
		component: () =>
			import(
				"@/views/clients/pf/blending/DetalleBlendingPf.vue"
			),
		meta: {
			pageTitle: "Detalle de Blending",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},

	{
		path: "/pf/planificaciones/historico-planificaciones",
		name: "pf-historico-planificacion",
		component: () =>
			import("@/views/clients/pf/planificaciones/HistoricoPlanificaciones.vue"),
		meta: {
			pageTitle: "Planificación Recetas",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},

	{
    path: "/pf/faq",
    name: "pf-faq",
    component: () =>
      import(
        "@/views/clients/pf/faq/Faq.vue"
      ),
    meta: {
      pageTitle: "Preguntas Frecuentes",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

	/* {
		path: "/pf/historico-notificaciones",
		name: "pf-historico-notificaciones",
		component: () =>
				import(
						"@/views/clients/pf/notificaciones/NotificationsList.vue"
				),
		meta: {
				pageTitle: "Registro de Actividad",
				breadcrumb: [
						{
								text: "",
								active: true,
						},
				],
		},
  },  */
	{
		path: "/pf/blending/detalle-planificacion/semana/:semana",
		name: "pf-historico-planificacion-detail",
		component: () => import("@/views/clients/pf/blending/detalle-planificacion/DetallePlanificacion.vue"),
		meta: {
			pageTitle: "Detalle Planificación Recetas",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
  
	{
		path: "/pf/planificaciones/dashboard-global/:semana",
		name: "pf-planificacion-global",
		component: () =>
			import(
				"@/views/clients/pf/planificaciones/dashboard-global/DashboardGlobal.vue"
			),
		meta: {
			pageTitle: "Dashboard Global",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
	
	{
		path: "/pf/not-active",
		name: "not-active",
		component: () =>
			import(
				"@/views/clients/pf/error/NotActive.vue"
			),
		meta: {
			pageTitle: "No activo",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},

	{
		path: "/pf/blending/planificacion/historico/detalle/semana/:semana/receta/:receta/",
		name: "dashboard-pf-blending-detalle-planificacion-blending",
		component: () => import("@/views/clients/pf/blending/detalle-planificacion/detalle-semanal/DetallesPlanificacionBlending.vue"),
		meta: {
			pageTitle: "Detalle Planificación Receta",
			breadcrumb: [
				{

					text: "",
					active: true,
				}
			]
		}
	},

	{
		path: "/pf/blending/detalle-planificacion/comparar-recetas/:semana/",
		name: "compare-recipe",
		component: () =>
			import(
				"@/views/clients/pf/blending/detalle-planificacion/comparador/CompareRecipe.vue"
			),
		meta: {
			pageTitle: "Comparación de Optimizaciones",
			breadcrumb: [ 
				{
					text: "",
					active: true,
				},
			],
		},
	},
	
];