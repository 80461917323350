export default [
    {
        header: "MÓDULOS"
    },


    {
        icon: "HomeIcon",
        title: "Home",
        children:
            [

                {
                    icon: "CircleIcon",
                    title: "Inicio",
                    route:
                    {
                        name: "canias-home",
                    }
                },


            ]
    },


    {
        icon: "DatabaseIcon",
        title: "Data",
        children:
            [

                {
                    icon: "CircleIcon",
                    title: "Histórico de Data",
                    route:
                    {
                        name: "canias-historico-data",
                    }
                },




            ]
    },

    {
        icon: "TrendingUpIcon",
        title: "Predicciones",
        children:
            [
                {
                    icon: "CircleIcon",
                    title: "Simulación de Escenarios",
                    route:
                    {
                        name: "canias-simulacion-escenarios",
                    }
                },
                {
                    icon: "CircleIcon",
                    title: "Histórico Predicciones",
                    route:
                    {
                        name: "canias-historico-predicciones",
                    }
                },

                
                {
                    icon: "CircleIcon",
                    title: "Real vs Predicción",
                    route:
                    {
                        name: "canias-historico-comparacion-predicciones",
                    }
                },
                
            ]
    },


    /*
    {
        icon: "SettingsIcon",
        title: "Ajustes y restricciones",
        children:
        [
            {
                icon: "CircleIcon",
                title: "Ajustes",
                route:
                {
                    name: "canias-ajustes",
                }
            },
            


            


            
        ]

        
    },

*/





    {
        icon: "HelpCircleIcon",
        title: "Ayuda",
        children:
            [
                {
                    icon: "CircleIcon",
                    title: "FAQ",
                    route:
                    {
                        name: "canias-faq",
                    }
                },

                {
                    icon: "CircleIcon",
                    title: "Políticas de privacidad",
                    route:
                    {
                        name: "canias-politicas",
                    }
                },
            ]
    }
]