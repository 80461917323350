export default [
    {
        header: "Modules"
    },
    {
        icon: "HomeIcon",
        title: "Home",
        children:
            [
                {
                    icon: "CircleIcon",
                    title: "Inicio",
                    route:
                    {
                        name: "fiordo-home",
                    }
                },
            ]
    },
    {
        icon: "DatabaseIcon",
        title: "Data",
        children:
            [

                {
                    icon: "CircleIcon",
                    title: "Carga de Archivo",
                    route:
                    {
                        name: "dashboard-fiordo-carga-data",
                    }
                },

                {
                    icon: "CircleIcon",
                    title: "Demanda",
                    children: [ 
    
                        {
                            icon: "CircleIcon",
                            title: "Historico Demanda",
                            route:
                            {
                                name: "dashboard-fiordo-hitorico-demanda",
                            }
                        },

                        {
                            icon: "CircleIcon",
                            title: "Ingreso Demanda",
                            route:
                            {
                                name: "dashboard-fiordo-data-ingreso-contratos",
                            }
                        },
                        /*
                        {
                            icon: "CircleIcon",
                            title: "Consolidando Demanda",
                            route:
                            {
                                name: "dashboard-fiordo-consolidando-demanda",
                            }
                        },*/
                    ]
                },  
                {
                    icon: "CircleIcon",
                    title: "Stock Lotes",
                    route:
                    {
                        name: "dashboard-fiordo-historico-stock",
                    }
                },
                
                
                

                {
                    icon: "CircleIcon",
                    title: "Asociación Lotes QAD",
                    route:
                    {
                        name: "dashboard-fiordo-asociacion-lotes",
                    }
                },
            ]
    },
    {
        icon: "LayersIcon",
        title: "Blending",
        children:
            [
                {
                    icon: "CircleIcon",
                    title: "Nueva Planificación",
                    route:
                    {
                        name: "dashboard-fiordo-nueva-planificacion",
                    }
                },
                {
                    icon: "CircleIcon",
                    title: "Histórico Planificación",
                    route:
                    {
                        name: "dashboard-fiordo-historico-planificacion",
                    }
                },
                {
                    icon: "CircleIcon",
                    title: "Comparación Real v/s Planificado",
                    route:
                    {
                        name: "dashboard-fiordo-blending-historico-blending",
                    }
                },
                {
                    icon: "CircleIcon",
                    title: "Reasignación Lotes",
                    route:
                    {
                        name: "dashboard-fiordo-reasignacion",
                    }
                },
            ]
    },

    {
        icon: "SettingsIcon",
        title: "Ajustes y restricciones",
        children:
            [
                {
                    icon: "CircleIcon",
                    title: "Lineas Productivas",
                    route:
                    {
                        name: "dashboard-fiordo-restricciones-lineas-productivas",
                    }
                },
                {
                    icon: "CircleIcon",
                    title: "SKU/Precios/Calidad",
                    route:
                    {
                        name: "dashboard-fiordo-restricciones-sku",
                    }
                },
                /*
                {
                    icon: "CircleIcon",
                    title: "Operaciones",
                    route:
                    {
                        name: "dashboard-fiordo-restricciones-operacionales",
                    }
                },*/
                {
                    icon: "CircleIcon",
                    title: "Logística",
                    route:
                    {
                        name: "dashboard-fiordo-restricciones-logistica",
                    }
                },
            ]
    },

    {
        icon: "HelpCircleIcon",
        title: "Ayuda",
        children:
            [
                {
                    icon: "CircleIcon",
                    title: "FAQ",
                    route:
                    {
                        name: "dashboard-fiordo-faq",
                    }
                },


                {
                    icon: "CircleIcon",
                    title: "Registro de Actividad",
                    route:
                    {
                        name: "dashboard-fiordo-hitorico-notificaciones",
                    }
                },
            ]
    }
]
