export default [
	{
		path: "/",
		name: "sap-home",
		component: () => import("@/views/clients/sapdemo/home/Home.vue"),
		meta: {
			pageTitle: "Inicio",
			breadcrumb: [
				{
					text: "Panel de inicio Caña Brava Sap integración",
					active: true
				}
			]
		}
	},
	{
		path: "/data/sap-integracion",
		name: "sap-integracion",
		component: () =>
			import("@/views/clients/sapdemo/data/sap/SapData.vue"),
		meta: {
			pageTitle: "SAP intelligence agriculture",
			breadcrumb: [
				{
					text: "",
					active: true,
				},
			],
		},
	},
	{
		path: "/data/plantaciones/",
		name: "sap-data-plantaciones",
		component: () => import("@/views/clients/sapdemo/data/plantaciones/HistoricoPlantaciones.vue"),
		meta: {
			pageTitle: "Historico plantaciones",
			breadcrumb: [
				{
					text: "Historico Plantaciones - Sap integración",
					active: true
				}
			]
		}
	},
	{
		path: "/data/plantaciones/clima",
		name: "sap-data-clima",
		component: () => import("@/views/clients/sapdemo/data/plantaciones/clima/HistoricoClima.vue"),
		meta: {
			pageTitle: "Historico Clima",
			breadcrumb: [
				{
					text: "Historico Clima - Sap integración",
					active: true
				}
			]
		}
	},
	{
		path: "/data/cosechas",
		name: "sap-data-cosechas",
		component: () => import("@/views/clients/sapdemo/data/cosechas/HistoricoCosechas.vue"),
		meta: {
			pageTitle: "Historico Cosechas",
			breadcrumb: [
				{
					text: "Historico Cosechas - Sap integración",
					active: true
				}
			]
		}
	},
	{
		path: "/data/zonasclimaticas",
		name: "sap-data-zonasclimaticas",
		component: () => import("@/views/clients/sapdemo/data/cosechas/HistoricoCosechas.vue"),
		meta: {
			pageTitle: "Zonas climaticas",
			breadcrumb: [
				{
					text: "Zonas climaticas - Sap integración",
					active: true
				}
			]
		}
	},
	{
		path: "/predicciones/cosechas",
		name: "sap-predicciones-cosechas",
		component: () => import("@/views/clients/sapdemo/predicciones/cosechas/prediccionesCosechas.vue"),
		meta: {
			pageTitle: "Zonas climaticas",
			breadcrumb: [
				{
					text: "Prediccion - Sap integración",
					active: true
				}
			]
		}
	},
	{
    path: "/not-active",
    name: "not-active",
    component: () =>
      import(
        "@/views/clients/sapdemo/error/NotActive.vue"
      ),
    meta: {
      pageTitle: "No activo",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
]