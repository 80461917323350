
import ApiServices from "../apiServices";
import axios from '@axios' 

export default class AdminApiServices extends ApiServices 
{
    constructor() {
      super("/admin");


      
    }


  }

  
