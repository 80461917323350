
import ApiServices from "../apiServices";

import axios from '@axios'

export default class FiordoApiServices extends ApiServices {
    constructor() {
        super("/fiordo");


        this.planificacionCreate = this.baseUrl + "/planificaciones/create"
        this.planificacionRead = this.baseUrl + "/planificaciones/read"

        this.restriccionesSkuConfig = this.baseUrl + "/restriccionesSku/config"
        this.restriccionesSkuData = this.baseUrl + "/restriccionesSku/data"
        this.restriccionesSkuEdit = this.baseUrl + "/restriccionesSku/edit"

        // Lojistica
        this.lojisticaMatrizAConfig = this.baseUrl + "/lojisticaMatrizA/config"
        this.lojisticaMatrizAData = this.baseUrl + "/lojisticaMatrizA/data"
        this.lojisticaMatrizAEdit = this.baseUrl + "/lojisticaMatrizA/edit"
        this.lojisticaMatrizBConfig = this.baseUrl + "/lojisticaMatrizB/config"
        this.lojisticaMatrizBData = this.baseUrl + "/lojisticaMatrizB/data"
        this.lojisticaMatrizBEdit = this.baseUrl + "/lojisticaMatrizB/edit"

        this.detallesPlanificacionConfig = this.baseUrl + "/detallesPlanificacionBlending/config"
        this.detallesPlanificacionData = this.baseUrl + "/detallesPlanificacionBlending/data"

        this.detallesPlanificacionTableConfig2 = this.baseUrl + "/detallesPlanificacionBlending2/config"
        this.detallesPlanificacionTableData2 = this.baseUrl + "/detallesPlanificacionBlending2/data"

        this.detallesPlanificacionTableConfig3 = this.baseUrl + "/detallesPlanificacionBlending3/config"
        this.detallesPlanificacionTableData3 = this.baseUrl + "/detallesPlanificacionBlending3/data"

        this.detallesPlanificacionTableConfig4 = this.baseUrl + "/detallesPlanificacionBlending4/config"
        this.detallesPlanificacionTableData4 = this.baseUrl + "/detallesPlanificacionBlending4/data"

        this.detallesCabulco = this.baseUrl + "/detallesPlanificacion/detalle"
        this.detallesCoronel = this.baseUrl + "/detallesPlanificacion/detalle2"

        this.detallesPlanificacionBlendingSemanalConfig = this.baseUrl + "/detallesPlanificacionBlendingSemanal/config"
        this.detallesPlanificacionBlendingSemanalData = this.baseUrl + "/detallesPlanificacionBlendingSemanal/data"

        this.detallesPlanificacionBlendingSemanal2Config = this.baseUrl + "/detallesPlanificacionBlendingSemanal2/config"
        this.detallesPlanificacionBlendingSemanal2Data = this.baseUrl + "/detallesPlanificacionBlendingSemanal2/data"

        this.detallesPlanificacionBlendingSemanal3Config = this.baseUrl + "/detallesPlanificacionBlendingSemanal3/config"
        this.detallesPlanificacionBlendingSemanal3Data = this.baseUrl + "/detallesPlanificacionBlendingSemanal3/data"

        this.detallesPlanificacionBlendingSemanal4Config = this.baseUrl + "/detallesPlanificacionBlendingSemanal4/config"
        this.detallesPlanificacionBlendingSemanal4Data = this.baseUrl + "/detallesPlanificacionBlendingSemanal4/data"

        this.detallesPlanificacionBlendingRecipeSemanalData = this.baseUrl + "/detallesPlanificacionBlendingRecipeSemanal/data"

        this.detallesSemanalStatics = this.baseUrl + "/detallesPlanificacionSemanal/statistics"
        this.detalleSemanalConfigCalbuco = this.baseUrl + "/detallesPlanificacionSemanal/config-calbuco"
        this.detalleSemanalDataCalbuco = this.baseUrl + "/detallesPlanificacionSemanal/data-calbuco"    //calbuco
        this.detalleSemanalConfigCoronel = this.baseUrl + "/detallesPlanificacionSemanal/config-coronel"

        this.detalleSemanalDataCoronel = this.baseUrl + "/detallesPlanificacionSemanal/data-coronel"


        // Ingreso Contratos - Start
        this.ingresoContratosConfig = this.baseUrl + "/ingresoContratos/config"
        this.ingresoContratosData = this.baseUrl + "/ingresoContratos/data"
        this.ingresoContratosStatistics = this.baseUrl + "/ingresoContratos/statistics"
        this.ingresoContratosCreate = this.baseUrl + "/contratos/create"
        this.ingresoContratosDelete = this.baseUrl + "/contratos/delete"

        // Historico Blending Planificado - Start
        this.historicoBlendingConfig = this.baseUrl + "/historicoBlending/config"
        this.historicoBlendingData = this.baseUrl + "/historicoBlending/data"
        this.historicoBlendingEdit = this.baseUrl + "/historicoBlending/edit"
        this.historicoBlendingStatistics = this.baseUrl + "/historicoBlending/statistics"
        this.historicoBlendingChart = this.baseUrl + "/historicoBlending/chart"

        // Detalle Historico Blending Planificado - Start
        this.detalleBlendingChart = this.baseUrl + "/detalleBlending/chart"
        this.detalleBlendingTableConfig = this.baseUrl + "/detalleBlending/table/config"
        this.detalleBlendingTableData = this.baseUrl + "/detalleBlending/table/data"
        this.detalleBlendingTableEdit = this.baseUrl + "/detalleBlending/table/edit"

        // Detalle Match Contratos - Start
        this.detalleMatchContratosConfig = this.baseUrl + "/detalleMatchContratos/config"
        this.detalleMatchContratosData = this.baseUrl + "/detalleMatchContratos/data"
        this.detalleMatchContratosEdit = this.baseUrl + "/detalleMatchContratos/edit"

        // Detalle Match Contratos Asignados - Start
        this.detalleMatchContratosAsignadosConfig = this.baseUrl + "/detalleMatchContratosAsignados/config"
        this.detalleMatchContratosAsignadosData = this.baseUrl + "/detalleMatchContratosAsignados/data"
        this.detalleMatchContratosAsignadosEdit = this.baseUrl + "/detalleMatchContratosAsignados/edit"

        // Reasignar Lote Contrato Asignados - Start
        this.reasignarLoteAsignadoConfig = this.baseUrl + "/reasignarLoteAsignado/config"
        this.reasignarLoteAsignadoData = this.baseUrl + "/reasignarLoteAsignado/data"
        this.reasignarLoteAsignadoEdit = this.baseUrl + "/reasignarLoteAsignado/edit"

        // Reasignar Lote Contrato Reemplazo - Start
        this.reasignarLoteReemplazoConfig = this.baseUrl + "/reasignarLoteReemplazo/config"
        this.reasignarLoteReemplazoData = this.baseUrl + "/reasignarLoteReemplazo/data"
        this.reasignarLoteReemplazoEdit = this.baseUrl + "/reasignarLoteReemplazo/edit"

        this.preciosNoruegaCreate = this.baseUrl + "/preciosNoruega/create"

        this.preciosUsaCreate = this.baseUrl + "/preciosUSA/create"

        this.historicoFreightsConfig = this.baseUrl + "/historicoFreights/config"
        this.historicoFreightsData = this.baseUrl + "/historicoFreights/data"
        this.historicoFreightsEdit = this.baseUrl + "/historicoFreights/edit"


        this.historicoBuyersConfig = this.baseUrl + "/historicoBuyers/config"
        this.historicoBuyersData = this.baseUrl + "/historicoBuyers/data"
        this.historicoBuyersEdit = this.baseUrl + "/historicoBuyers/edit"

        this.predictionsLast = this.baseUrl + "/predictions/last";

        this.historicoDemandaConfig = this.baseUrl + "/historicoDemanda/config"
        this.historicoDemandaData = this.baseUrl + "/historicoDemanda/data"

        this.historicoPrediccionesConfig = this.baseUrl + "/historicoPredicciones/config"
        this.historicoPrediccionesData = this.baseUrl + "/historicoPredicciones/data"
        this.historicoPrediccionesEdit = this.baseUrl + "/historicoPredicciones/edit"
        this.historicoPrediccionesStatistics = this.baseUrl + "/historicoPredicciones/statistics"

        this.historicoNotificacionesConfig = this.baseUrl + "/historicoNotificaciones/config"
        this.historicoNotificacionesData = this.baseUrl + "/historicoNotificaciones/data"


        this.historicoStockConfig = this.baseUrl + "/historicoStock/config"
        this.historicoStockData = this.baseUrl + "/historicoStock/data"
        this.historicoStockStats = this.baseUrl + "/historicoStock/statistics"



        this.detallesCabulco = this.baseUrl + "/detallesPlanificacion/detalle"
        this.detallesCoronel = this.baseUrl + "/detallesPlanificacion/detalle2"

        this.detallesPlanificacionBlendingSemanalConfig = this.baseUrl + "/detallesPlanificacionBlendingSemanal/config"
        this.detallesPlanificacionBlendingSemanalData = this.baseUrl + "/detallesPlanificacionBlendingSemanal/data"

        this.detallesPlanificacionBlendingSemanal2Config = this.baseUrl + "/detallesPlanificacionBlendingSemanal2/config"
        this.detallesPlanificacionBlendingSemanal2Data = this.baseUrl + "/detallesPlanificacionBlendingSemanal2/data"

        this.detallesPlanificacionBlendingSemanal3Config = this.baseUrl + "/detallesPlanificacionBlendingSemanal3/config"
        this.detallesPlanificacionBlendingSemanal3Data = this.baseUrl + "/detallesPlanificacionBlendingSemanal3/data"

        this.detallesPlanificacionBlendingSemanal4Config = this.baseUrl + "/detallesPlanificacionBlendingSemanal4/config"
        this.detallesPlanificacionBlendingSemanal4Data = this.baseUrl + "/detallesPlanificacionBlendingSemanal4/data"

        this.detallesPlanificacionBlendingRecipeSemanalData = this.baseUrl + "/detallesPlanificacionBlendingRecipeSemanal/data"

        this.detallesSemanalStatics = this.baseUrl + "/detallesPlanificacionSemanal/statistics"
        this.detalleSemanalConfigCalbuco = this.baseUrl + "/detallesPlanificacionSemanal/config-calbuco"
        this.detalleSemanalDataCalbuco = this.baseUrl + "/detallesPlanificacionSemanal/data-calbuco"    //calbuco
        this.detalleSemanalConfigCoronel = this.baseUrl + "/detallesPlanificacionSemanal/config-coronel"

        this.detalleSemanalDataCoronel = this.baseUrl + "/detallesPlanificacionSemanal/data-coronel"

        this.matchContratosStatics = this.baseUrl + "/detallesPlanificacionMatch/statistics"
        this.matrizSatisfaccionConfig = this.baseUrl + "/detallesPlanificacionMatch/config"
        this.matizSatisfaccionData = this.baseUrl + "/detallesPlanificacionMatch/data"

        this.detallesPlanificacionConfig = this.baseUrl + "/detallesPlanificacionBlending/config"
        this.detallesPlanificacionData = this.baseUrl + "/detallesPlanificacionBlending/data"

        this.detallesPlanificacionTableConfig2 = this.baseUrl + "/detallesPlanificacionBlending2/config"
        this.detallesPlanificacionTableData2 = this.baseUrl + "/detallesPlanificacionBlending2/data"

        this.detallesPlanificacionTableConfig3 = this.baseUrl + "/detallesPlanificacionBlending3/config"
        this.detallesPlanificacionTableData3 = this.baseUrl + "/detallesPlanificacionBlending3/data"

        this.detallesPlanificacionTableConfig4 = this.baseUrl + "/detallesPlanificacionBlending4/config"
        this.detallesPlanificacionTableData4 = this.baseUrl + "/detallesPlanificacionBlending4/data"

        this.detallesPlanificacionRecipeSum = this.baseUrl + "/detallesPlanificacionBlendingRecipe/sum"
        this.detallesPlanificacionRecipeData = this.baseUrl + "/detallesPlanificacionBlendingRecipe/data2"
        this.detallesPlanificacionRecipeEdit = this.baseUrl + "/detallesPlanificacionBlendingRecipe/edit2"

        this.historicoCagesConfig = this.baseUrl + "/historicoCages/config"
        this.historicoCagesData = this.baseUrl + "/historicoCages/data"
        this.historicoCagesEdit = this.baseUrl + "/historicoCages/edit"

        this.historicoPlanificacionesConfig = this.baseUrl + "/historicoPlanificaciones/config"
        this.historicoPlanificacionesData = this.baseUrl + "/historicoPlanificaciones/data"
        this.historicoPlanificacionesEdit = this.baseUrl + "/historicoPlanificaciones/edit"

        this.historicoPlanificacionesStatistics = this.baseUrl + "/historicoPlanificaciones/statistics"

        this.stockJaulasChart00 = this.baseUrl + "/stockJaulas/chart0"
        this.stockJaulasChart01 = this.baseUrl + "/stockJaulas/chart1"
        this.stockJaulasChart02 = this.baseUrl + "/stockJaulas/chart2"
        this.stockJaulasConfig = this.baseUrl + "/stockJaulas/config"
        this.stockJaulasData = this.baseUrl + "/stockJaulas/data"
        this.stockJaulasEdit = this.baseUrl + "/stockJaulas/edit"

        this.stockJaulasCentros = this.baseUrl + "/stockJaulas/centros"

        this.potencialStockChart0 = this.baseUrl + "/potencialStock/chart0"
        this.potencialStockChart1 = this.baseUrl + "/potencialStock/chart1"
        this.potencialStockChart2 = this.baseUrl + "/potencialStock/chart2"
        this.potencialStockConfig = this.baseUrl + "/potencialStock/config"
        this.potencialStockData = this.baseUrl + "/potencialStock/data"
        this.potencialStockEdit = this.baseUrl + "/potencialStock/edit"
        this.potencialStockStatistics = this.baseUrl + "/potencialStock/statistics"

        this.planificacionStockData = this.baseUrl + "/planificacionStock/data"
        this.planificacionStockConfig = this.baseUrl + "/planificacionStock/config"

        this.globalCoronelChart0 = this.baseUrl + "/globalCoronel/chart0"
        this.globalCoronelChart1 = this.baseUrl + "/globalCoronel/chart1"
        this.globalCoronelChart2 = this.baseUrl + "/globalCoronel/chart2"
        this.globalCoronelStatistics1 = this.baseUrl + "/globalCoronel/statistics1"
        this.globalCoronelStatistics2 = this.baseUrl + "/globalCoronel/statistics2"
        this.globalCoronelStatistics3 = this.baseUrl + "/globalCoronel/statistics3"
        this.globalCoronelStatistics4 = this.baseUrl + "/globalCoronel/statistics4"

        this.globalCalbucoChart0 = this.baseUrl + "/globalCalbuco/chart0"
        this.globalCalbucoChart1 = this.baseUrl + "/globalCalbuco/chart1"
        this.globalCalbucoChart2 = this.baseUrl + "/globalCalbuco/chart2"
        this.globalCalbucoStatistics1 = this.baseUrl + "/globalCalbuco/statistics1"
        this.globalCalbucoStatistics2 = this.baseUrl + "/globalCalbuco/statistics2"
        this.globalCalbucoStatistics3 = this.baseUrl + "/globalCalbuco/statistics3"
        this.globalCalbucoStatistics4 = this.baseUrl + "/globalCalbuco/statistics4"

        this.detallePrediccionChart = this.baseUrl + "/detallePrediccion/chart"
        this.detallePrediccionTableConfig = this.baseUrl + "/detallePrediccion/table/config"
        this.detallePrediccionTableData = this.baseUrl + "/detallePrediccion/table/data"
        this.detallePrediccionTableEdit = this.baseUrl + "/detallePrediccion/table/edit"

        this.demandaIrrestrictaMonthsCategories = this.baseUrl + "/demandaIrrestricta/monthsCategories";
        this.demandaIrrestrictaChart00 = this.baseUrl + "/demandaIrrestricta/chart0";
        this.demandaIrrestrictaChart01 = this.baseUrl + "/demandaIrrestricta/chart1";
        this.demandaIrrestrictaChart02 = this.baseUrl + "/demandaIrrestricta/chart2";
        this.demandaIrrestrictaChart03 = this.baseUrl + "/demandaIrrestricta/chart3";
        this.demandaIrrestrictaChart04 = this.baseUrl + "/demandaIrrestricta/chart4";

        this.demandaIrrestrictaChart05 = this.baseUrl + "/demandaIrrestricta/chart5";

        this.demandaIrrestrictaTable00 = this.baseUrl + "/demandaIrrestricta/table0";

        this.demandaIrrestrictaTable01 = this.baseUrl + "/demandaIrrestricta/table1";

        this.historicoLineasProductivasConfig = this.baseUrl + "/historicoLineasProductivas/config"
        this.historicoLineasProductivasData = this.baseUrl + "/historicoLineasProductivas/data"
        this.historicoLineasProductivasEdit = this.baseUrl + "/historicoLineasProductivas/edit"



        this.asociarLotesBrunaConfig = this.baseUrl + "/asociarLotes/bruna/config"
        this.asociarLotesBrunaData = this.baseUrl + "/asociarLotes/bruna/data"
        this.asociarLotesBrunaEdit = this.baseUrl + "/asociarLotes/bruna/edit"
        this.asociarLotesQadConfig = this.baseUrl + "/asociarLotes/qad/config"
        this.asociarLotesQadData = this.baseUrl + "/asociarLotes/qad/data"
        this.asociarLotesQadEdit = this.baseUrl + "/asociarLotes/qad/edit"

        this.settingsLast = this.baseUrl + "/settings/last";

        this.reasignacionLotesConfig01 = this.baseUrl + "/reasignacionLotes/config1"
        this.reasignacionLotesData01 = this.baseUrl + "/reasignacionLotes/data1"
        this.reasignacionLotesEdit01 = this.baseUrl + "/reasignacionLotes/edit1"

        this.reasignacionLotesConfig02 = this.baseUrl + "/reasignacionLotes/config2"
        this.reasignacionLotesData02 = this.baseUrl + "/reasignacionLotes/data2"
        this.reasignacionLotesEdit02 = this.baseUrl + "/reasignacionLotes/edit2"

        this.reasignacionLotesConfig03 = this.baseUrl + "/reasignacionLotes/config3"
        this.reasignacionLotesData03 = this.baseUrl + "/reasignacionLotes/data3"
        this.reasignacionLotesEdit03 = this.baseUrl + "/reasignacionLotes/edit3"

        this.planificacionReasignacionLotesConfig01 = this.baseUrl + "/planificacion/reasignacionLotes/config1"
        this.planificacionReasignacionLotesData01 = this.baseUrl + "/planificacion/reasignacionLotes/data1"
        this.planificacionReasignacionLotesEdit01 = this.baseUrl + "/planificacion/reasignacionLotes/edit1"

        this.planificacionReasignacionLotesConfig02 = this.baseUrl + "/planificacion/reasignacionLotes/config2"
        this.planificacionReasignacionLotesData02 = this.baseUrl + "/planificacion/reasignacionLotes/data2"
        this.planificacionReasignacionLotesEdit02 = this.baseUrl + "/planificacion/reasignacionLotes/edit2"


    }
    postIngresoContratosCreate(data, vue) {
        return axios.post(this.ingresoContratosCreate, data)

    }
    postContratoDelete(data, vue) {
        return axios.post(this.ingresoContratosDelete, data)
    }


    postPlanificacionCreate(data, vue) {
        return axios.post(this.planificacionCreate, data)

    }
}



