export default [
    {
        header: "Modules"
    },
    {
        icon: "HomeIcon",
        title: "Home",
        title: "Inicio",
        route:
        {
            name: "home",
        }

    },


    {
        icon: "SlidersIcon",
        title: "Simulación de Escenarios",
        route:
        {
            name: "simulacion-escenarios",
        }
    },

    
    {
        icon: "TrelloIcon",
        title: "Optimización",
        route:
        {
            name: "optimizacion",
        }
    },

    {
        icon: "ListIcon",
        title: "Historico Optimizacion",
        route:
        {
            name: "historico-optimizations",
        }
    },

    

    {
        icon: "ListIcon",
        title: "Predicción Quebra",
        route:
        {
            name: "prediccion-quebra",
        }
    },    


    {
        icon: "ListIcon",
        title: "Predicción Anomalía",
        route:
        {
            name: "prediccion-anomalia",
        }
    },  


    {
        icon: "SettingsIcon",
        title: "Ajustes",
        route:
        {
            name: "ajustes",
        }
    },

   
    /*
    {
        icon: "HelpCircleIcon",
        title: "Ayuda",
        children:
            [
                {
                    icon: "CircleIcon",
                    title: "FAQ",
                    route:
                    {
                        name: "dashboard-fiordo-faq",
                    }
                },


             
            ]
    }*/
]
