export default [

  { path: "/", redirect: { name: "manufactura-home" } },

  // menu manufactura
  {
    path: "/manufactura/home",
    name: "manufactura-home",
    component: () => import("@/views/clients/manufactura/home/Home.vue"),
    meta: {
      pageTitle: "Inicio",
      breadcrumb: [
        {
          text: "Consola de Administracion",
          active: true
        }
      ]
    }
  },
  {
    path: "/manufactura/carga-data",
    name: "manufactura-carga-data",
    component: () =>
      import(
        "@/views/clients/manufactura/carga-data/CargaData.vue"
      ),
    meta: {
      pageTitle: "Carga de Archivos",
      breadcrumb: [
        {
          text: "",
          active: true
        }
      ]
    }
  },
  {
    path: "/manufactura/skus",
    name: "manufactura-skus",
    component: () => import("@/views/clients/manufactura/skus/Skus.vue"),
    meta: {
      pageTitle: "Skus",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/manufactura/demanda",
    name: "manufactura-demanda",
    component: () => import("@/views/clients/manufactura/demanda/Demanda.vue"),
    meta: {
      pageTitle: "Demanda",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/manufactura/lineas-productivas",
    name: "manufactura-ajustes-lineas-productivas",
    component: () =>
      import("@/views/clients/manufactura/ajustes-restricciones/lineas-productivas/LineasProductivas.vue"),
    meta: {
      pageTitle: "Ajustes Líneas Productivas",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/manufactura/prediccion/modelos",
    name: "manufactura-modelos-prediccion",
    component: () =>
      import(
        "@/views/clients/manufactura/ModuloNoActivado.vue"
      ),
    meta: {
      pageTitle: "Modelos de Predicción",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {

    path: "/manufactura/prediccion/historico",
    name: "manufactura-historico-prediccion",
    component: () =>
      import(
        "@/views/clients/manufactura/ModuloNoActivado.vue"
      ),
    meta: {
      pageTitle: "Histórico de Predicciones",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/manufactura/ajustes",
    name: "manufactura-ajustes",
    component: () => import("@/views/clients/manufactura/ajustes/Ajustes.vue"),
    meta: {
      pageTitle: "Ajustes",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/manufactura/faq",
    name: "manufactura-faq",
    component: () =>
      import(
        "@/views/clients/camanchaca/faq/Faq.vue"
      ),
    meta: {
      pageTitle: "Preguntas Frecuentes",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/manufactura/terminos-y-condiciones",
    name: "manufactura-terminos-y-condiciones",
    component: () =>
      import(
        "@/views/clients/manufactura/ayuda/terminosycondiciones.vue"
      ),
    meta: {
      pageTitle: "Preguntas Frecuentes",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/manufactura/nueva-planificacion",
    name: "manufactura-nueva-planificacion2",
    component: () =>
      import(
        "@/views/clients/manufactura/nueva-planificacion/NuevaPlanificacion2.vue"
      ),
    meta: {
      pageTitle: "Nueva Planificación",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/manufactura/historico-planificaciones",
    name: "manufactura-historico-planificaciones",
    component: () =>
      import(
        "@/views/clients/manufactura/planificaciones/HistoricoPlanificaciones.vue"
      ),
    meta: {
      pageTitle: "Histórico Planificaciones",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/manufactura/historico-planificaciones/detail/:planification_id",
    name: "manufactura-planification-detail",
    component: () =>
      import(
        "@/views/clients/manufactura/planificacion-detalle/DetallePlanificacion.vue"
      ),
    meta: {
      pageTitle: "Detalle de Planificación",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/manufactura/optimizations/compare-optimizations/",
    name: "compare-optimizations",
    component: () =>
      import(
        "@/views/clients/manufactura/historico-optimizations/CompareOptimizations.vue"
      ),
    meta: {
      pageTitle: "Comparación de Optimizaciones",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },




];
