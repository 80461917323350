
import ApiServices from "../apiServices";

import axios from '@axios'

export default class FiordoApiServices extends ApiServices {
    constructor() {
        super("/klabin");

        this.modelsRead2 = this.baseUrl + "/models/read"

        this.modelsCreateInvocationUrl = this.baseUrl + "/models/create_invocation"
        this.modelsLastInvocationUrl = this.baseUrl + "/models/last_invocation"


        this.historicoInvocationsConfig = this.baseUrl + "/historicoInvocations/config"
        this.historicoInvocationsData = this.baseUrl + "/historicoInvocations/data"

        this.historicoPrediccionQuebraConfig = this.baseUrl + "/historicoPrediccionQuebra/config"
        this.historicoPrediccionQuebraData = this.baseUrl + "/historicoPrediccionQuebra/data"
        this.historicoPrediccionQuebraChart = this.baseUrl + "/historicoPrediccionQuebra/chart"
        this.historicoPrediccionQuebraStats = this.baseUrl + "/historicoPrediccionQuebra/stats"

        this.historicoPrediccionAnomaliaConfig = this.baseUrl + "/historicoPrediccionAnomalia/config"
        this.historicoPrediccionAnomaliaData = this.baseUrl + "/historicoPrediccionAnomalia/data"
        this.historicoPrediccionAnomaliaChart = this.baseUrl + "/historicoPrediccionAnomalia/chart"
        this.historicoPrediccionAnomaliaStats = this.baseUrl + "/historicoPrediccionAnomalia/stats"



        this.historicoOptimizationsConfig = this.baseUrl + "/historicoOptimizations/config"
        this.historicoOptimizationsData = this.baseUrl + "/historicoOptimizations/data"

 
        this.detalleOptimizacion = {

            detalleOptimizacionTable: this.baseUrl + "/detalleOptimization/detalleOptimizacionTable",
            detalleOptimizacionTable2: this.baseUrl + "/detalleOptimization/detalleOptimizacionTable2",
            detalleOptimizacionParallelChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionParallelChart",
            detalleOptimizacionStats: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats",
            detalleOptimizacionGensChart: this.baseUrl + "/detalleOptimization/detalleOptimizacionGensChart",

            detalleOptimizacionStats2: this.baseUrl + "/detalleOptimization/detalleOptimizacionStats2",

            
            

        };

        this.optimizations = {

            create: this.baseUrl + "/optimizations/create",

            getLastEscenarios: this.baseUrl + "/optimizations/getLastEscenarios",
            getLastEscenario: this.baseUrl + "/optimizations/getLastEscenario",


        };


        this.historicoOptimizaciones = {

            compareOptimizationsVersusRealChart: this.baseUrl + "/historicoOptimizaciones/compareOptimizationsVersusRealChart",

        };        


        this.compareInvocations = {
            compareInvocationsMetrics: this.baseUrl + "/compareInvocations/compareInvocationsMetrics",
            compareInvocationsMetricsTable: this.baseUrl + "/compareInvocations/compareInvocationsMetricsTable",
            compareInvocationsMetricsParallel: this.baseUrl + "/compareInvocations/compareInvocationsMetricsParallel",
            compareAllInvocationsMetricsParallel: this.baseUrl + "/compareInvocations/compareAllInvocationsMetricsParallel",

        }
       
        this.compareOptimizations = {
            compareOptimizationsMetrics: this.baseUrl + "/compareOptimizations/compareOptimizationsMetrics",
            compareOptimizationsMetricsTable: this.baseUrl + "/compareOptimizations/compareOptimizationsMetricsTable",
            compareOptimizationsMetricsParallel: this.baseUrl + "/compareOptimizations/compareOptimizationsMetricsParallel",
            compareAllOptimizationsMetricsParallel: this.baseUrl + "/compareOptimizations/compareAllOptimizationsMetricsParallel",

        }
       




    }

    modelsRead(data, vue) {
        return axios.post(this.modelsRead2, data)

    }

    optimizationsCreate(data, vue) {
        return axios.post(this.optimizations.create, data)
    }

}


