export default [
    { path: "/", redirect: { name: "blumar-home" } },
  
    {
      path: "/acuicultura/ajustes",
      name: "blumar-ajustes",
      component: () =>
        import("@/views/clients/acuicultura/ajustes/Ajustes.vue"),
      meta: {
        pageTitle: "Ajustes",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    }, 
  
    {
      path: "/acuicultura/ajustes/lineas-productivas",
      name: "blumar-ajustes-lineas-productivas",
      component: () =>
        import("@/views/clients/acuicultura/ajustes-restricciones/lineas-productivas/LineasProductivas.vue"),
      meta: {
        pageTitle: "Ajustes Lineas Productivas",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    
    {
      path: "/acuicultura/ajustes/cosecha-produccion",
      name: "blumar-ajustes-cosecha-y-produccion",
      component: () =>
        import("@/views/clients/acuicultura/ajustes-restricciones/cosecha-y-produccion/CosechaYProduccion.vue"),
      meta: {
        pageTitle: "Degradaciones",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/acuicultura/ajustes/comerciales",
      name: "blumar-ajustes-comerciales",
      component: () =>
        import("@/views/clients/acuicultura/ajustes-restricciones/comerciales/Comerciales.vue"),
      meta: {
        pageTitle: "Ajustes Comerciales",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
  
    {
      path: "/acuicultura/stock-jaulas",
      name: "blumar-stock-jaulas",
      component: () =>
        import("@/views/clients/acuicultura/jaulas/StockJaulas.vue"),
      meta: {
        pageTitle: "Stock Jaulas",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/acuicultura/stock-transito",
      name: "blumar-stock-transito",
      component: () =>
        import("@/views/clients/acuicultura/jaulas/StockJaulas.vue"),
      meta: {
        pageTitle: "Stock Transito",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/acuicultura/stock-acopio",
      name: "blumar-stock-acopio",
      component: () =>
        import("@/views/clients/acuicultura/jaulas/StockJaulas.vue"),
      meta: {
        pageTitle: "Stock Acopio",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/acuicultura/historico-clientes",
      name: "blumar-historico-clientes",
      component: () => import("@/views/clients/acuicultura/buyers/BuyersList.vue"),
      meta: {
        pageTitle: "Histórico de Clientes",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/acuicultura/historico-demanda",
      name: "blumar-historico-demanda",
      component: () =>
        import("@/views/clients/acuicultura/demanda/HistoricoDemanda.vue"),
      meta: {
        pageTitle: "Histórico de Demanda",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    }, 
    
    { 
      path: "/acuicultura/carga-data",
      name: "blumar-carga-data",
      component: () =>
        import("@/views/clients/acuicultura/carga-data/CargaData.vue"),
      meta: {
        pageTitle: "Carga de Archivo",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    }, 
  
  
    {
      path: "/acuicultura/home",
      name: "blumar-home",
      component: () => import("@/views/clients/acuicultura/home2/Home.vue"),
      meta: {
        pageTitle: "Inicio",
        breadcrumb: [
          {
            text: "Consola de Administración",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/acuicultura/compra-mp",
      name: "blumar-compra-materia-prima",
      component: () => import("@/views/clients/acuicultura/compra-mp/CompraMateriaPrima.vue"),
      meta: {
        pageTitle: "Compra MP",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/acuicultura/historico-planificaciones/detail/:planification_id",
      name: "blumar-planification-detail",
      component: () =>
        import(
          "@/views/clients/acuicultura/planificacion-detalle/DetallePlanificacion.vue"
        ),
      meta: {
        pageTitle: "Detalle de Planificación",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
   
    {
      path: "/acuicultura/historico",
      name: "blumar-historico-predicciones",
      component: () =>
        import("@/views/clients/acuicultura/predicciones2/Historico.vue"),
      meta: {
        pageTitle: "Histórico de Predicciones",
        breadcrumb: [  
          {
            text: "",
            active: true,
          },
        ],
      },
    }, 
    
    {
      path: "/acuicultura/modelos",
      name: "blumar-modelos-resultados",
      component: () =>
        import("@/views/clients/acuicultura/modelos/ResumenModelo.vue"),
      meta: {
        pageTitle: "Arquitectura de Modelos de Predicción",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    /*
    {
      path: "/acuicultura/planning",
      name: "blumar-planning",
      component: () => import("@/views/clients/acuicultura/planning/Planning.vue"),
      meta: {
        pageTitle: "Plan de cosecha 90 días",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
   */
    {
      path: "/acuicultura/nueva-planificacion",
      name: "blumar-nueva-planificacion",
      component: () =>
        import(
          "@/views/clients/acuicultura/nueva-planificacion/NuevaPlanificacion.vue"
        ),
      meta: {
        pageTitle: "Nueva Planificación",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    {
      path: "/acuicultura/demanda-irrestricta",
      name: "blumar-demanda-irrestricta",
      component: () =>
        import(
          "@/views/clients/acuicultura/demanda-irrestricta-v2/DemandaIrrestricta.vue"
        ),
      meta: {
        pageTitle: "Demanda Irrestricta Consolidada",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    {
      path: "/acuicultura/carga-formularios-ingreso-contratos",
      name: "blumar-ingreso-contratos",
      component: () =>
        import(
          "@/views/clients/acuicultura/carga-formularios/IngresoContrato.vue"
        ),
      meta: {
        pageTitle: "Ingreso de Contrato",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/acuicultura/carga-formularios-ingreso-forecast",
      name: "blumar-ingreso-forecast",
      component: () =>
        import(
          "@/views/clients/acuicultura/carga-formularios/IngresoForecast.vue"
        ),
      meta: {
        pageTitle: "Ingreso Forecast",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/acuicultura/skus",
      name: "blumar-skus",
      component: () => import("@/views/clients/acuicultura/skus/SkusList.vue"),
      meta: {
        pageTitle: "Skus",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/acuicultura/freights",
      name: "blumar-freights",
      component: () =>
        import("@/views/clients/acuicultura/freights/FreightsList.vue"),
      meta: {
        pageTitle: "Fletes",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/acuicultura/ingreso-precios",
      name: "blumar-precios",
      component: () =>
        import("@/views/clients/acuicultura/precios/IngresoPrecios.vue"),
      meta: {
        pageTitle: "Ingreso Precios",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      }, 
    },
  
   
    {
      path: "/acuicultura/historico-planificaciones",
      name: "blumar-historico-planificaciones",
      component: () =>
        import(
          "@/views/clients/acuicultura/planificaciones/HistoricoPlanificaciones.vue"
        ),
      meta: {
        pageTitle: "Histórico Planificaciones",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
  
    {
      path: "/acuicultura/comparar-planificaciones",
      name: "blumar-comparar-planificaciones",
      component: () =>
        import(
          "@/views/clients/acuicultura/planificaciones/CompararPlanificaciones.vue"
        ),
      meta: {
        pageTitle: "Comparador Planificaciones",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },  
  
  
  
    /*
    {
      path: "/acuicultura/simulacion-escenarios",
      name: "blumar-simulacion-escenarios",
      component: () =>
        import(
          "@/views/clients/acuicultura/planificaciones2/HistoricoPlanificaciones.vue"
        ),
      meta: {
        pageTitle: "Simulación de Escenarios",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
    {
      path: "/acuicultura/comparacion-escenarios-all",
      name: "blumar-comparacion-escenarios-all",
      component: () =>
        import(
          "@/views/clients/acuicultura/planificaciones2/ComparacionEscenariosAll.vue"
        ),
      meta: {
        pageTitle: "Comparación de Escenarios",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
  
    {
      path: "/acuicultura/simulacion-escenarios/compare-runs/",
      name: "compare-runs",
      component: () =>
        import(
          "@/views/clients/acuicultura/planificaciones2/CompareRuns.vue"
        ),
      meta: {
        pageTitle: "Compare Runs",
        breadcrumb: [ 
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  */
  
   
  
  
  
    /*
    {
      path: "/acuicultura/plan-cosecha",
      name: "blumar-plan-cosecha",
      component: () =>
        import("@/views/clients/acuicultura/plan-cosecha/PlanCosecha.vue"),
      meta: {
        pageTitle: "Plan Cosecha",
        breadcrumb: [
          {
            // text:
            //     "Estas viendo la vista inicial de Historico planificaciones. ",
            text: "",
            active: true,
          },
        ],
      },
    },
    */
    {
      path: "/acuicultura/cosecha-urgente",
      name: "blumar-cosecha-urgente",
      component: () => import("@/views/vuexy/error/Error404.vue"),
      meta: {
        pageTitle: "Cosecha Urgente",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    {
      path: "/acuicultura/ajustes-restricciones-cosecha",
      name: "blumar-cosecha",
      component: () =>
        import("@/views/clients/acuicultura/ajustes-restricciones/Cosecha.vue"),
      meta: {
        pageTitle: "Cosecha",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    {
      path: "/acuicultura/ajustes-restricciones-matriz-sku",
      name: "blumar-matriz-sku",
      component: () =>
        import("@/views/clients/acuicultura/ajustes-restricciones/MatrizSku.vue"),
      meta: {
        pageTitle: "Matriz SKU/Líneas Productivas",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    {
      path: "/acuicultura/ajustes-restricciones-lineas-costos",
      name: "blumar-lineas-costos",
      component: () =>
        import(
          "@/views/clients/acuicultura/ajustes-restricciones/LineasCostos.vue"
        ),
      meta: {
        pageTitle: "Líneas y Costos Productivos",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    {
      path: "/acuicultura/ajustes-restricciones-curva-crecimiento",
      name: "blumar-curva-crecimiento",
      component: () =>
        import(
          "@/views/clients/acuicultura/ajustes-restricciones/CurvaCrecimiento.vue"
        ),
      meta: {
        pageTitle: "Curva de Crecimiento",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    {
      path: "/acuicultura/ajustes-restricciones-descansos-centros",
      name: "blumar-descansos-centros",
      component: () =>
        import(
          "@/views/clients/acuicultura/ajustes-restricciones/DescansoCentros.vue"
        ),
      meta: {
        pageTitle: "Descansos Centros",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    /*
    {
      path: "/acuicultura/materia-prima-comprada",
      name: "blumar-materia-prima-comprada",
      component: () =>
        import(
          "@/views/clients/acuicultura/materia-prima-comprada/MateriaPrimaComprada.vue"
        ),
      meta: {
        pageTitle: "Materia Prima Comprada",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },*/
  
    {
      path: "/acuicultura/muestreo-jaulas/dashboard-global",
      name: "blumar-muestreo-jaulas-dashboard-global",
      component: () =>
        import(
          "@/views/clients/acuicultura/muestreo-jaulas/DashboardGlobalMuestreo.vue"
        ),
      meta: {
        pageTitle: "Dashboard Global Muestreo",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    {
      path: "/acuicultura/muestreo-jaulas/identificacion-niveles-defectos",
      name: "blumar-muestreo-jaulas-identificacion-niveles-defectos",
      component: () =>
        import(
          "@/views/clients/acuicultura/muestreo-jaulas/IdentificacionNivelesDefectos.vue"
        ),
      meta: {
        pageTitle: "Identificación Niveles de Defectos",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
    {
      path: "/acuicultura/muestreo-jaulas/formulario-muestreo",
      name: "blumar-muestreo-jaulas-formulario-muestreo",
      component: () =>
        import(
          "@/views/clients/acuicultura/muestreo-jaulas/FormularioMuestreo.vue"
        ),
      meta: {
        pageTitle: "Formulario Muestreo",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },  
     
    {
      path: "/acuicultura/historico-notificaciones",
      name: "blumar-hitorico-notificaciones",
      component: () =>
        import(
          "@/views/clients/acuicultura/notificaciones/NotificationsList.vue"
        ), 
      meta: { 
        pageTitle: "Registro de Actividad",
        breadcrumb: [
          { 
            text: "",  
            active: true,
          }, 
        ],
      },  
    },
   
    {
      path: "/acuicultura/faq",
      name: "blumar-faq",
      component: () =>
        import(
          "@/views/clients/acuicultura/faq/Faq.vue"
        ),
      meta: {
        pageTitle: "Preguntas Frecuentes",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
    },
  
  
    
  ];
  