
import ApiServices from "../apiServices";
import axios from '@axios' 

export default class AcuiculturaApiServices extends ApiServices 
{
    constructor() {
      super("/blumar");


      this.comparePlanifications = {

        comparePlanificationsMetrics: this.baseUrl + "/comparePlanifications/comparePlanificationsMetrics",
        comparePlanificationsMetricsTable: this.baseUrl + "/comparePlanifications/comparePlanificationsMetricsTable",
        comparePlanificationsMetricsParallel: this.baseUrl + "/comparePlanifications/comparePlanificationsMetricsParallel",


    }
  
    }



    postDemandaDelete(data, vue)
    {
        return axios.post(this.historicoDemandaDelete, data)

    }    


    postFreightDelete(data, vue)
    {
        return axios.post(this.freightsDelete, data)

    }      

  }

  
