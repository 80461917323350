export default [
    {
        header: "Modules"
    },


    {
        icon: "HomeIcon",
        title: "Home",
        children:
        [

            {
                icon: "CircleIcon",
                title: "Inicio",
                route:
                {
                    name: "cmp-home",
                }
            },
                        

        ]
    },


    {
        icon: "DatabaseIcon",
        title: "Data",
        children:
        [

            {
                icon: "CircleIcon",
                title: "Histórico de Datos",
                route:
                {
                    name: "cmp-historico-data",
                }
            },            
         

                        
            
        ]
    },

    {
        icon: "TrendingUpIcon",
        title: "Predicciones",
        children:
        [
            {
                icon: "CircleIcon",
                title: "Simulación de Escenarios",
                route:
                {
                    name: "cmp-simulacion-escenarios",
                }
            },
            {
                icon: "CircleIcon",
                title: "Histórico Predicciones y Mezclas",
                route:
                {
                    name: "cmp-historico-predicciones-mezclas",
                }
            },

            {
                icon: "CircleIcon",
                title: "Real vs Predicción",
                route:
                {
                    name: "cmp-historico-comparacion-predicciones",
                }
            },
            
            /*
            {
                icon: "CircleIcon",
                title: "Predicción Continua",
                route:
                {
                    name: "cmp-historico-comparacion-prediccion-continua",
                }
            },*/

            {
                icon: "CircleIcon",
                title: "Predicción Continua",
                route:
                {
                    name: "cmp-historico-comparacion-prediccion-continua",
                }
            },

        ]
    }, 


    {
        icon: "SettingsIcon",
        title: "Ajustes y restricciones",
        children:
        [
            {
                icon: "CircleIcon",
                title: "Ajustes",
                route:
                {
                    name: "cmp-ajustes",
                }
            },
            


            


            
        ]

        
    },


    {
        icon: "HelpCircleIcon",
        title: "Ayuda",
        children:
            [
                {
                    icon: "CircleIcon",
                    title: "FAQ",
                    route:
                    {
                        name: "cmp-faq",
                    }
                },
                {
                    icon: "CircleIcon",
                    title: "Registro de Actividades",
                    route:
                    {
                        name: "cmp-registro",
                    }
                },
                {
                    icon: "CircleIcon",
                    title: "Términos y condiciones",
                    route:
                    {
                        name: "cmp-terminos-y-condiciones",
                    }
                }
            ]
    }
]
