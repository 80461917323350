

export default [
  {
    header: "Modules"
  },
  {
    icon: "HomeIcon",
    title: "Home",
    children:
      [

        {
          icon: "CircleIcon",
          title: "Inicio",
          route:
          {
            name: "molymet-home",
          }
        },

      ]
  },
  {
    icon: "DatabaseIcon",
    title: "Data",
    children:
      [

        {
          icon: "CircleIcon",
          title: "Carga de Archivo",
          route:
          {
            name: "molymet-carga-data",
          }
        },
        {
          icon: "CircleIcon",
          title: "Materia Prima",
          children: [


            {
              icon: "CircleIcon",
              title: "Stock Existencias",
              route:
              {
                name: "molymet-stock-existencias",
              }
            },
            {
              icon: "CircleIcon",
              title: "Calidades Analitos",
              route:
              {
                name: "molymet-calidad-analitos",
              }
            },
          ]
        },
      ]
  },
  {
    icon: "TrendingUpIcon",
    title: "Predicciones",
    children:
      [
        {
          icon: "CircleIcon",
          title: "Predicciones analitos",
          route:
          {
            name: "molymet-historico-predicciones",
          }
        }
      ]
  },
  {
    icon: "LayersIcon",
    title: "Blending",
    children:
      [
        {
          icon: "CircleIcon",
          title: "Sugerencia de mezclas",
          route:
          {
            name: "molymet-sugerencia-mezcla",
          }
        }
      ]
  },
]
