export default [
  {
    path: "/",
    name: "tip-home",
    component: () => import("@/views/clients/tip/home/Home.vue"),
    meta: {
      pageTitle: "Inicio",
      breadcrumb: [
        {
          text: "Panel de inicio Tip",
          active: true
        }
      ]
    }
  },

  {
    path: "/tip/operaciones/inventario",
    name: "tip-operaciones-inventario",
    component: () =>
      import("@/views/clients/tip/operaciones/Inventario.vue"),
    meta: {
      pageTitle: "Inventario",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/tip/operaciones/cria",
    name: "tip-operaciones-cria",
    component: () =>
      import("@/views/clients/tip/operaciones/Cria.vue"),
    meta: {
      pageTitle: "Cría",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/tip/finanzas",
    name: "tip-finanzas",
    component: () =>
      import("@/views/clients/tip/finanzas/Finanzas.vue"),
    meta: {
      pageTitle: "Finanzas",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/tip/propuestas",
    name: "tip-propuestas",
    component: () =>
      import("@/views/clients/tip/propuestas/Propuestas.vue"),
    meta: {
      pageTitle: "Propuestas",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/tip/analisis",
    name: "tip-analisis",
    component: () =>
      import("@/views/clients/tip/analisis/Analisis.vue"),
    meta: {
      pageTitle: "Análisis Datos",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
];
