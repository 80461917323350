import * as Sentry from "@sentry/vue";
import clientsServiceDefaultConfig from "./clientsServiceDefaultConfig";

import { initialAbility } from '@/libs/acl/config'

import router from "@/router/index.js"

export default class ClientsService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  clientsServiceConfig = { ...clientsServiceDefaultConfig };

  isAlreadyFetchingAccessToken = false


  constructor(axiosIns, clientsServiceOverrideConfig) {
    this.axiosIns = axiosIns;
    this.clientsServiceConfig = {
      ...this.clientsServiceConfig,
      ...clientsServiceOverrideConfig,
    };




    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getToken();
        const userData = this.getUserData();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.clientsServiceConfig.tokenType} ${accessToken}`;
        }

        config.attempNum ??= 0
        config.attempMaxRetries ??= 5
        config.attempBase ??= 2000

        if(userData){
          Sentry.setUser({ 
            id: userData.id,
            ip_address: "{{auto}}",
            email: userData.email,
            username: userData.name,
            name: userData.name,
            client:  userData.client.name,

          });
        }


        console.log("axiosIns", "url:", config.url, "attempNum:", config.attempNum, "attempMaxRetries",  config.attempMaxRetries );

        return config;
      },
      (error) => Promise.reject(error)
    ); 

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => {

        //console.log({response})
        
        if(response.config.url !== this.clientsServiceConfig.loginEndpoint && this.isTokenAboutToExpired())
        {
          console.log("isTokenAboutToExpired");

          if(!this.isAlreadyFetchingAccessToken)
          {
            this.isAlreadyFetchingAccessToken = true;

            const accessToken = this.getToken();

            if(accessToken)
            {
              this.refreshToken().then((r) => 
              {
                //console.log(r);
  
                // Update accessToken in localStorage
                this.setToken(r.data.access_token);
                this.setExpirationDate(r.data.expires_in); 
  
                this.isAlreadyFetchingAccessToken = false;
  

 
              });
  


            }



          }

        }        
        
        
        return response
      },
      (error) => {

        //console.log({error})

        let { config, response } = error;

        //console.log({config, response})

        if (response && response.status === 401)
        {
          this.logout()
        
        }
        // https://axios-http.com/docs/handling_errors
        else if (error.response) 
        {
          console.log("The request was made and the server responded with a status code that falls out of the range of 2xx")
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //console.log(error.response.data);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          return this.retryRequest(config, error)
        } 
        else if (error.request) {

          console.log("The request was made but no response was received")

          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          //console.log(error.request);

          return this.retryRequest(config, error)
          //return Promise.reject(error);
        } 
        else {

          console.log("Something happened in setting up the request that triggered an Error")          
          // Something happened in setting up the request that triggered an Error
          //console.log('Error', error.message);
          return this.retryRequest(config, error)
        }        


        return Promise.reject(error);
      }
    );
  }

  // https://javascript.plainenglish.io/how-to-retry-requests-using-axios-64c2da8340a7
  // https://blog.michaelstrasser.com/2017/11/retrying-backend-service-calls-with-backoff/
  retryRequest(config, err)
  {
    if (!config || config.attempNum > config.attempMaxRetries) 
    {
      return Promise.reject(err);
    }

    config.attempNum += 1

    //let retryMs = Math.random() * (2**config.attempNum) * config.attempBase
    let retryMs = (2**config.attempNum) * config.attempBase

    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        console.log("Retry the request", config.url, "at (ms)", retryMs, "attempNum:", config.attempNum );
        resolve();
      }, retryMs)
    })

    return delayRetryRequest.then(() => this.axiosIns(config));
  }

  getToken() {
    return localStorage.getItem(this.clientsServiceConfig.storageTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.clientsServiceConfig.storageTokenKeyName, value);
  }

  setExpirationDate(expiresInSeconds) {

    if(expiresInSeconds == null)
    {
      localStorage.removeItem(this.clientsServiceConfig.storageTokenExpirationDate)      
    }
    else
    {
      let expiration = new Date().getTime() + (expiresInSeconds * 1000)

      localStorage.setItem(this.clientsServiceConfig.storageTokenExpirationDate, expiration);
  
    }

  }  

  getExpirationDate() {

    let expiration = localStorage.getItem(this.clientsServiceConfig.storageTokenExpirationDate);

    if(expiration == null){
      return null
    }

    return new Date(parseInt(expiration));
  }

  isTokenAboutToExpired(delta = 15*60*1000)
  {
    let expirationDate = this.getExpirationDate()

    if(expirationDate == null){
      return false
    }

    return expirationDate - new Date() < delta;
    

  }

  login(...args) {
    return this.axiosIns.post(this.clientsServiceConfig.loginEndpoint, ...args);
  }

  refreshToken(...args) {
    return this.axiosIns.post(this.clientsServiceConfig.refreshTokenEndpoint, ...args);
  }
  
  getUserData(){

    let userDataString = localStorage.getItem('userData');

    if(userDataString == null){
      return null;
    }

        let userData = JSON.parse(userDataString);

    return userData

  }

  logout(){

      Sentry.setUser(null);

 // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(this.clientsServiceConfig.storageTokenKeyName)
      localStorage.removeItem(this.clientsServiceConfig.storageTokenExpirationDate)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      //this.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' })


  }


  me(...args) {
    return this.axiosIns.post(this.clientsServiceConfig.meEndpoint, ...args);
  }

  /*
  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }
*/

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

}
