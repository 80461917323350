export default [
  {
    path: "/",
    name: "nestle-home",
    component: () => import("@/views/clients/nestle/home/Home.vue"),
    meta: {
      pageTitle: "Inicio",
      breadcrumb: [
        {
          text: "Panel de inicio nestle",
          active: true
        }
      ]
    }
  },
  {
    path: "/historico-data",
    name: "nestle-historico-data",
    component: () =>
      import(
        "@/views/clients/nestle/data/HistoricoAutomationResults.vue"
      ),
    meta: {
      pageTitle: "Histórico de Data",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/nestle-simulacion-escenarios",
    name: "nestle-simulacion-escenarios",
    component: () =>
      import(
        "@/views/clients/nestle/optimizaciones/HistoricoPlanificaciones.vue"
      ),
    meta: {
      pageTitle: "Simulación de Escenarios",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },

  {
    path: "/historico-predicciones",
    name: "nestle-historico-predicciones",
    component: () =>
      import(
        "@/views/clients/nestle/historico-predicciones/HistoricoPredicciones2.vue"
      ),
    meta: {
      pageTitle: "Histórico de Predicciones",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
  {
    path: "/not-active",
    name: "not-active",
    component: () =>
      import(
        "@/views/clients/nestle/error/NotActive.vue"
      ),
    meta: {
      pageTitle: "No activo",
      breadcrumb: [
        {
          text: "",
          active: true,
        },
      ],
    },
  },
];
